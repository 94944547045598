import { Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { ComplianceCardSection } from './styles'
import CardInfo from '../CardInfo'

interface ISectionCard {
  cards: any[]
  onCardClick: (cardType: string) => void
  selected: string
}

const SectionCard: React.FC<ISectionCard> = ({
  cards,
  onCardClick,
  selected,
}) => {
  const [selectedCard, setSelectedCard] = useState(selected)
  if (!cards?.length)
    return (
      <ComplianceCardSection>
        <Grid container spacing={6}>
          {[0, 1, 2, 3].map((card) => (
            <Grid key={card} item xs={6} sm={6} md={4} lg={3}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </ComplianceCardSection>
    )

  return cards?.length ? (
    <ComplianceCardSection>
      <Grid container spacing={6} alignItems="stretch">
        {!!cards?.length &&
          cards.map((card) => {
            return (
              card.value !== null && (
                <Grid key={card.key} item xs={12} sm={6} md={4} lg={3}>
                  <CardInfo
                    textContent={
                      card.type === 'percent'
                        ? `${parseFloat(card.value.toString()).toFixed(0)}%`
                        : card.value
                    }
                    cardType="secondary"
                    hover
                    square={false}
                    elevation={1}
                    cursor={true}
                    textFooter={card.name}
                    onClick={() => {
                      setSelectedCard(card.key)
                      onCardClick(card.key)
                    }}
                    selected={selectedCard === card.key}
                  />
                </Grid>
              )
            )
          })}
      </Grid>
    </ComplianceCardSection>
  ) : null
}

export default SectionCard
