import { Box, Grid, Paper, Tab, Tabs, Typography, Theme } from '@mui/material'
import React, { useState } from 'react'
import { usePageTitleContext } from 'src/context/PageTitleHook'
import { Content } from 'everchain-uilibrary'
import { makeStyles } from '@mui/styles'
import ExpiringDocuments from './ExpiringDocuments'
import Reports from './Reports/Reports'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const DocumentPortal: React.FC = () => {
  const classes = useStyles()
  const { setTitle } = usePageTitleContext()
  const [tabSelected, setTabSelected] = React.useState(0)

  const handleTabChange = (event: any, newTab: number) => {
    setTabSelected(newTab)
  }

  useState(() => {
    setTitle('Reports')
  })

  return (
    <Content>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Document Portal</Typography>
        </Grid>
      </Grid>
      <Paper>
        <div className={classes.root} style={{ marginTop: 6 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabSelected}
            onChange={handleTabChange}
            className={classes.tabs}
          >
            <Tab
              data-cy="tab-category"
              label="Expiring documents"
              {...a11yProps(0)}
            />
            <Tab data-cy="tab-jurisdiction" label="Reports" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabSelected} index={0}>
            <ExpiringDocuments />
          </TabPanel>
          <TabPanel value={tabSelected} index={1}>
            <Reports />
          </TabPanel>
        </div>
      </Paper>
    </Content>
  )
}

export default DocumentPortal
