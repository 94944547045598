import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { BACKEND_URL } from 'src/api/axios/api'
import { State } from '@progress/kendo-data-query'
import { GetAccessToken } from 'src/api/axios/helper'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import QuestionCommandCell from 'src/components/GridCommandCell/QuestionCommandCell'
import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from '../NewDocumentRegistration/style'

const Question: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')

  const [data, setData] = useState<DocumentType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [total, setTotal] = useState(0)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const userToken = GetAccessToken()
  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })
  const canManage =
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    )

  const getData = () => {
    axios({
      method: 'get',
      url: `${BACKEND_URL}/question/getall`,
      params: { pagination: encodeURIComponent(JSON.stringify(gridState)) },
      headers: {
        Authorization: userToken,
      },
    }).then((result: any) => {
      setData(result.data.questions)
      setTotal(result.data.total)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState])

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues] = useState({
    enabled: true,
    statement: '',
  })

  const inputDataFormSchema = Yup.object().shape({
    enabled: Yup.bool().required('Required'),
    statement: Yup.string().nullable().required('Required'),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        enabled: values.enabled,
        statement: values.statement,
      }

      setLoading(true)
      axios({
        method: 'post',
        url: `${BACKEND_URL}/question`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('New data added', notistackSucces)
          setOpenAddDialog(false)
          getData()
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data, notistackError)
          setLoading(false)
        })

      setSubmitting(false)
      inputDataForm.resetForm()
    },
  })

  const CommandCell = (props: GridCellProps) => (
    <QuestionCommandCell
      dataItem={props.dataItem}
      confirmCallback={() => {
        setLoading(true)
        axios({
          method: 'delete',
          url: `${BACKEND_URL}/question`,
          params: { id: props.dataItem.id },
          headers: {
            Authorization: userToken,
          },
        })
          .then(() => {
            enqueueSnackbar('Data deleted', notistackSucces)

            getData()
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data, notistackError)
            setLoading(false)
          })
      }}
      updateSuccededCallback={() => {
        setLoading(true)
        getData()
        setLoading(false)
      }}
    />
  )

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  return (
    <>
      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container>
          <Can
            do={
              PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup
            }
            on="any"
          >
            <Grid container>
              <Grid container justifyContent="flex-end">
                <Button
                  data-cy="create-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenAddDialog(true)
                  }}
                  disabled={!canManage}
                >
                  Create New Question
                </Button>
              </Grid>
            </Grid>
          </Can>
          <Grid container style={{ marginTop: '24px' }}>
            <Grid item>
              {loading && loadingPanel}

              <KendoGrid
                style={{
                  height: 'auto',
                  maxHeight: window.innerHeight * 0.6,
                }}
                data={data || []}
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
              >
                <KendoGridColumn
                  field="id"
                  title="ID"
                  width="120px"
                  cell={(props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {props.dataItem[field]}
                      </td>
                    )
                  }}
                />
                <KendoGridColumn
                  field="enabled"
                  title="Is Enabled?"
                  width="200px"
                  cell={(props: GridCellProps) => {
                    const field = props.field || ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn field="statement" title="Statement" />

                {canManage && (
                  <KendoGridColumn cell={CommandCell} width="160px" />
                )}
              </KendoGrid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false)
            inputDataForm.resetForm({ values: initialValues })
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Question
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                inputDataForm.resetForm({ values: initialValues })
                setOpenAddDialog(false)
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <form onSubmit={inputDataForm.handleSubmit}>
              {loading && loadingPanel}
              <Grid container style={{ width: '400px' }}>
                <Grid container direction="column" spacing={3}>
                  <Grid
                    item
                    data-cy="dialog-disable-enabled-selector"
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Disabled</Grid>
                    <Grid item>
                      <Switch
                        checked={inputDataForm.values.enabled}
                        onChange={inputDataForm.handleChange}
                        name="enabled"
                      />
                    </Grid>
                    <Grid item>Enabled</Grid>
                  </Grid>
                  <Grid item>
                    <TextField
                      data-cy="dialog-input-field-statement"
                      fullWidth
                      label="Statement"
                      multiline
                      className="custom-textfieldMultiline-label"
                      minRows={3}
                      variant="outlined"
                      name="statement"
                      value={inputDataForm.values.statement}
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.statement}
                      helperText={inputDataForm.errors.statement}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: '6px' }}
                  container
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      data-cy="cancel-button"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setOpenAddDialog(false)
                        inputDataForm.resetForm({ values: initialValues })
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="dialog-create-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!inputDataForm.dirty || !inputDataForm.isValid}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </form>
    </>
  )
}

export default Question
