import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DOCUMENT_REGISTRATION,
  DOCUMENT_PORTAL,
  DOCUMENT_SETUP,
  HELP_REQUEST,
  KNOWLEDGE_BASE,
  CERTIFICATION_EVENTS,
  DASHBOARD,
  DOCUMENT_REPOSITORY,
  THIRD_PARTY_REPOSITORY,
  DIGITAL_SELLER_SURVEY,
  CERTIFICATION_MANAGER,
  CORPORATE_SOP,
  LOAD_SOP,
  CORPORATE_SOP_REPOSITORY,
  SOP_ACCESS_CONTROL,
} from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

import {
  Icon,
  SideMenu,
  SideMenuItem,
  SideMenuSubItem,
} from 'everchain-uilibrary'

const Menus = () => {
  const history = useHistory()
  const [pageUrl, setPageUrl] = useState(history.location.pathname)
  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)

  const canManage =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
      'any'
    )

  const canView =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    ) && !canManage

  const location = useLocation()
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const iconFontSize = 'small'

  const sopUrls = [CORPORATE_SOP, LOAD_SOP, SOP_ACCESS_CONTROL]
  const repositoryUrls = [
    DOCUMENT_REPOSITORY,
    THIRD_PARTY_REPOSITORY,
    CORPORATE_SOP_REPOSITORY,
  ]

  const isURL = (paths: string[]) => {
    let result = false
    for (const p of paths) {
      if (pageUrl.indexOf(p) !== -1) {
        result = true
        break
      }
    }
    return result
  }

  useEffect(() => {
    setPageUrl(location.pathname)
  }, [location])

  return (
    <SideMenu>
      {isInternal && (
        <SideMenuItem
          id="menu-item-dashboard"
          title="UDD Dashboard"
          onClick={() => history.push(DASHBOARD)}
          icon={<Icon name="Dashboard" fontSize={iconFontSize} />}
          defaultOpen={pageUrl.indexOf(DASHBOARD) !== -1}
        />
      )}
      <SideMenuItem
        id="menu-item-document-registration"
        title="Document Registration"
        onClick={() => history.push(DOCUMENT_REGISTRATION)}
        icon={<Icon name="Folder" fontSize={iconFontSize} />}
        defaultOpen={pageUrl.indexOf(DOCUMENT_REGISTRATION) !== -1}
      />
      <SideMenuItem
        id="menu-item-survey-setup"
        title="Survey Setup"
        onClick={() => history.push(DIGITAL_SELLER_SURVEY)}
        icon={<Icon name="Notes" fontSize={iconFontSize} />}
        defaultOpen={pageUrl.indexOf(DIGITAL_SELLER_SURVEY) !== -1}
      />
      {isInternal && (
        <>
          <Can
            do={PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal}
            on="any"
          >
            {!canView && (
              <SideMenuItem
                id="menu-item-document-setup"
                title="Document Setup"
                onClick={() => history.push(DOCUMENT_SETUP)}
                icon={<Icon name="NoteAdd" fontSize={iconFontSize} />}
                defaultOpen={pageUrl.indexOf(DOCUMENT_SETUP) !== -1}
              />
            )}
            <SideMenuItem
              id="menu-item-certification-events"
              title="Certification Events"
              onClick={() => history.push(CERTIFICATION_EVENTS)}
              icon={<Icon name="Receipt" fontSize={iconFontSize} />}
              defaultOpen={pageUrl.indexOf(CERTIFICATION_EVENTS) !== -1}
            />
            <SideMenuItem
              id="menu-item-certification-manager"
              title="Certification Manager"
              onClick={() => history.push(CERTIFICATION_MANAGER)}
              icon={<Icon name="ChromeReaderMode" fontSize={iconFontSize} />}
              defaultOpen={pageUrl.indexOf(CERTIFICATION_MANAGER) !== -1}
            />
          </Can>
          <Can
            do={PermissionCodeAccess.ComplianceManagementCorporateSOP_Access}
            on="any"
          >
            <SideMenuItem
              id="menu-item-sop-certifications"
              title="SOP Certifications"
              onClick={() => {
                if (!isURL(sopUrls)) {
                  history.push(CORPORATE_SOP)
                }
              }}
              icon={<Icon name="ListAlt" fontSize={iconFontSize} />}
              defaultOpen={
                pageUrl.indexOf(CORPORATE_SOP) !== -1 ||
                pageUrl.indexOf(LOAD_SOP) !== -1 ||
                pageUrl.indexOf(SOP_ACCESS_CONTROL) !== -1
              }
            >
              <SideMenuSubItem
                id="menu-sub-item-corporate-sop"
                title="Corporate SOP"
                onClick={() => history.push(CORPORATE_SOP)}
                icon={<Icon name="ListAlt" fontSize={iconFontSize} />}
              />
              <Can
                do={
                  PermissionCodeAccess.ComplianceManagementCorporateSOP_Upload
                }
                on="any"
              >
                <SideMenuSubItem
                  id="menu-sub-item-load-sop"
                  title="Load SOP"
                  onClick={() => history.push(LOAD_SOP)}
                  icon={<Icon name="Description" fontSize={iconFontSize} />}
                />
                <SideMenuSubItem
                  id="menu-sub-item-sop-access-control"
                  title="SOP Access Control"
                  onClick={() => history.push(SOP_ACCESS_CONTROL)}
                  icon={<Icon name="Description" fontSize={iconFontSize} />}
                />
              </Can>
            </SideMenuItem>
          </Can>
          <Can
            do={PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal}
            on="any"
          >
            <SideMenuItem
              id="menu-item-document-portal"
              title="Document Portal"
              onClick={() => history.push(DOCUMENT_PORTAL)}
              icon={<Icon name="InsertDriveFile" fontSize={iconFontSize} />}
              defaultOpen={pageUrl.indexOf(DOCUMENT_PORTAL) !== -1}
            />
          </Can>
        </>
      )}
      {(ability.can(
        PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
        'any'
      ) ||
        ability.can(
          PermissionCodeAccess.ComplianceManagementSystem_Basic_RepositoryAccess,
          'any'
        ) ||
        ability.can(
          PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
          'any'
        )) && (
        <>
          <SideMenuItem
            id="menu-item-document-repository"
            title="Document Repository"
            onClick={() => {
              if (!isURL(repositoryUrls)) {
                history.push(DOCUMENT_REPOSITORY)
              }
            }}
            icon={<Icon name="FileCopy" fontSize={iconFontSize} />}
            defaultOpen={
              pageUrl.indexOf(DOCUMENT_REPOSITORY) !== -1 ||
              pageUrl.indexOf(THIRD_PARTY_REPOSITORY) !== -1 ||
              pageUrl.indexOf(CORPORATE_SOP_REPOSITORY) !== -1
            }
          >
            <SideMenuSubItem
              id="menu-sub-item-repository"
              title={isInternal ? 'Repository' : 'My Repository'}
              onClick={() => history.push(DOCUMENT_REPOSITORY)}
              icon={<Icon name="AssignmentInd" fontSize={iconFontSize} />}
            />
            {!isInternal && (
              <>
                <SideMenuSubItem
                  id="menu-sub-item-third-party-repository"
                  title="Third Party Repository"
                  onClick={() => history.push(THIRD_PARTY_REPOSITORY)}
                  icon={<Icon name="Assignment" fontSize={iconFontSize} />}
                />
                <Can
                  do={
                    PermissionCodeAccess.ComplianceManagementCorporateSOP_Access
                  }
                  on="any"
                >
                  <SideMenuSubItem
                    id="menu-sub-item-sop-repository"
                    title="SOP Repository"
                    onClick={() => history.push(CORPORATE_SOP_REPOSITORY)}
                    icon={<Icon name="Assignment" fontSize={iconFontSize} />}
                  />
                </Can>
              </>
            )}
          </SideMenuItem>
        </>
      )}
      {userPermissions.controlPanelPermission && (
        <SideMenuItem
          id="menu-item-control-panel"
          title="Control Panel"
          onClick={() => {
            window.open(process.env.REACT_APP_CONTROLPANEL_SITE, '_self')
          }}
          icon={
            <Icon name="SettingsApplicationsOutlined" fontSize={iconFontSize} />
          }
        />
      )}
      {userPermissions.marketPlacePermission && (
        <SideMenuItem
          id="menu-item-marketplace"
          title="Marketplace"
          onClick={() => {
            window.open(process.env.REACT_APP_MARKETPLACE_SITE, '_self')
          }}
          icon={<Icon name="AccountBalanceWallet" fontSize={iconFontSize} />}
        />
      )}
      {!process.env.REACT_APP_HIDE_FAQ_MENU && (
        <SideMenuItem
          id="menu-item-faq"
          title="FAQ"
          icon={<Icon name="Forum" fontSize={iconFontSize} />}
        >
          <SideMenuSubItem
            id="menu-sub-item-knowledge-base"
            title="Knowledge Base"
            onClick={() => window.open(KNOWLEDGE_BASE, '_blank')}
            icon={<Icon name="MenuBook" fontSize={iconFontSize} />}
          />
          <SideMenuSubItem
            id="menu-sub-item-help-request"
            title="Help Request"
            onClick={() => window.open(HELP_REQUEST, '_blank')}
            icon={<Icon name="HelpRounded" fontSize={iconFontSize} />}
          />
        </SideMenuItem>
      )}
    </SideMenu>
  )
}

Menus.defaultProps = {
  opendedMenu: false,
}

export default Menus
