import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { notistackOptions } from 'src/configs/notistackOptions'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'

import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DialogTitleStyled } from '../../../pages/NewDocumentRegistration/style'

interface AcceptRejectFileModalProps {
  open: boolean
  action: string
  requestId: number
  documentId: number
  isLicense: boolean
  Close: () => void
  approval: boolean
}

const AcceptRejectFileModal: React.FC<AcceptRejectFileModalProps> = ({
  open,
  action,
  requestId,
  documentId,
  isLicense,
  Close,
  approval,
}: AcceptRejectFileModalProps) => {
  const userToken = GetAccessToken()
  const { enqueueSnackbar } = useSnackbar()
  const notistackSuccess = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const queryClient = useQueryClient()
  const selectedCertificationPeriod = useContext(CertificationPeriodContext)
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const [openAcceptDialog, setOpenAcceptDialog] = useState<boolean>(false)
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false)
  const [rejectionReason, setRejectionReason] = useState<string>()
  const [expirationDate, setExpirationDate] = useState<any>(null)

  useEffect(() => {
    if (open) {
      if (action === 'Accept') setOpenAcceptDialog(true)
      else setOpenRejectDialog(true)
    } else if (action === 'Accept') setOpenAcceptDialog(false)
    else setOpenRejectDialog(false)
  }, [action, open])

  const mutationRejectFile = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'put',
        url: `${BACKEND_URL}/BusinessUpload`,
        params: {
          requestId,
          documentId,
          status: 'Rejected',
          comment: rejectionReason,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      enqueueSnackbar('Document has been rejected.', notistackSuccess)

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })

      queryClient.refetchQueries({
        queryKey: 'getAdditionalDocuments',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })

      setRejectionReason(undefined)
      Close()
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error rejecting document.', notistackError)
    },
  })

  const handleRejectFile = () => {
    if (approval) {
      mutationRejectApprovalFile.mutate()
    } else {
      mutationRejectFile.mutate()
    }
  }

  const expiringDateMessage = () => {
    if (isLicense)
      return 'Expiration date is required for licenses, please choose a expiration date below:'
    return 'Expiration date is optional for this type of document.'
  }

  const mutationAcceptFile = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'put',
        url: `${BACKEND_URL}/BusinessUpload`,
        params: {
          requestId,
          documentId,
          status: 'Accepted',
          comment: 'Document accepted',
          expirationDate,
          certificationDate: selectedCertificationPeriod.certificationDate,
          certificationPeriodId: selectedCertificationPeriod.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      enqueueSnackbar('Document accepted.', notistackSuccess)

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })

      queryClient.refetchQueries({
        queryKey: 'getAdditionalDocuments',
      })

      setExpirationDate(null)
      Close()
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error on Accept document.', notistackError)
    },
  })

  const mutationAcceptApprovalFile = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'put',
        url: `${BACKEND_URL}/Repository`,
        params: {
          requestId,
          documentId,
          status: 'Accepted',
          comment: 'Document accepted',
          expirationDate,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      enqueueSnackbar('Document accepted.', notistackSuccess)

      queryClient.refetchQueries({
        queryKey: 'getRepoFilesUploaded',
      })
      queryClient.refetchQueries({
        queryKey: 'documentRepositoryRequest',
      })
      queryClient.refetchQueries({
        queryKey: 'approvalDocumentsQuery',
      })

      setExpirationDate(null)
      Close()
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error on Accept document.', notistackError)
    },
  })

  const mutationRejectApprovalFile = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'put',
        url: `${BACKEND_URL}/Repository`,
        params: {
          requestId,
          documentId,
          status: 'Rejected',
          comment: rejectionReason,
          expirationDate,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      enqueueSnackbar('Document rejected.', notistackSuccess)

      queryClient.refetchQueries({
        queryKey: 'getRepoFilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'documentRepositoryRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'approvalDocumentsQuery',
      })

      setRejectionReason(undefined)
      Close()
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error on Accept document.', notistackError)
    },
  })

  const handleAcceptFile = () => {
    if (approval) {
      mutationAcceptApprovalFile.mutate()
    } else mutationAcceptFile.mutate()
  }

  return (
    <>
      <Dialog
        open={openRejectDialog}
        onClose={() => Close()}
        aria-labelledby="reject-dialog-title"
        aria-describedby="reject-dialog-description"
        maxWidth="md"
        PaperProps={{ style: { width: '500px' } }}
      >
        <DialogTitleStyled id="reject-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Rejection Reason
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => Close()}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <TextField
            multiline
            className="custom-textfieldMultiline-label"
            minRows={5}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 400 }}
            name="reason"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Reason"
            disabled={mutationRejectFile.isLoading}
          />
          <Typography
            style={{
              color: 'silver',
              textAlign: 'right',
            }}
          >
            {`${rejectionReason?.length ?? 0}/400`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setRejectionReason('')
              Close()
            }}
            disabled={mutationRejectFile.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!rejectionReason || mutationRejectFile.isLoading}
            onClick={handleRejectFile}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAcceptDialog}
        onClose={() => Close()}
        aria-labelledby="Accept-dialog-title"
        aria-describedby="Accept-dialog-description"
        maxWidth="lg"
        PaperProps={{ style: { width: '400px', height: '220px' } }}
      >
        <DialogTitleStyled id="Accept-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Expiration Date
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => Close()}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
            disabled={mutationAcceptFile.isLoading}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <p>{expiringDateMessage()}</p>
          <DatePicker
            value={expirationDate}
            onChange={(e) => setExpirationDate(e)}
            disabled={mutationAcceptFile.isLoading}
            country={country}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            hidden={!!isLicense}
            disabled={!expirationDate || mutationAcceptFile.isLoading}
            onClick={() => {
              setExpirationDate(null)
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptFile}
            disabled={mutationAcceptFile.isLoading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AcceptRejectFileModal
