import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useState } from 'react'
import { DialogTitleStyled } from '../../../pages/NewDocumentRegistration/style'

interface DeleteAdditionalRequestModalProps {
  open: boolean
  disableUI: boolean
  Confirm: () => void
  Close: () => void
}

const DeleteAdditionalRequestModal: React.FC<
  DeleteAdditionalRequestModalProps
> = ({
  open,
  disableUI,
  Confirm,
  Close,
}: DeleteAdditionalRequestModalProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  useEffect(() => {
    if (open) setOpenDialog(true)
    else setOpenDialog(false)
  }, [open])

  return (
    <>
      <Dialog open={openDialog} onClose={() => Close()} maxWidth="md">
        <DialogTitleStyled id="dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Delete Request
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => Close()}
            disabled={disableUI}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography>Would you like to delete this request?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => Close()}
            disabled={disableUI}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={Confirm}
            disabled={disableUI}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteAdditionalRequestModal
