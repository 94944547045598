/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { GetAccessToken } from 'src/api/axios/helper'
import { BusinessContext } from 'src/context/BusinessContext'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import Close from '@mui/icons-material/Close'
import { BACKEND_URL } from 'src/api/axios/api'
import DisclosureAuthorization from 'src/components/FillableForms/DisclosureAuthorization'
import { notistackOptions } from 'src/configs/notistackOptions'
import InformationSecurity from 'src/components/FillableForms/InformationSecurity'
import Insurance from 'src/components/FillableForms/Insurance'
import References from 'src/components/FillableForms/References'
import Executives from 'src/components/FillableForms/Executives'
import Businessinformation from 'src/components/FillableForms/BusinessInformation'
import CollectionAgencyManager from 'src/components/FillableForms/CollectionAgencyManager'
import ComplianceLegalRegulatory from 'src/components/FillableForms/ComplianceLegalRegulatory'
import { getValidations } from 'src/components/FillableForms/Validations/inputValidations'
import { useFormik } from 'formik'
import FillableActions from 'src/components/FillableForms/FillableActions'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ICertificationPeriod } from 'src/api/models'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { GlobalContext } from 'src/context/GlobalContext'
import { FilesToUpload } from 'src/utils/common'
import CompanyRegistrationsTradingNames from 'src/components/FillableForms/CompanyRegistrationsTradingNames'
import { DialogTitleStyled } from 'src/layouts/Main/styles'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { createUsersAndRoles } from 'src/api/operations/post'
import BankAccount from '../FillableForms/BankAccount'
import PortfolioAssetTypes from '../FillableForms/PortfolioAssetTypes'
import MediaSettings from '../FillableForms/MediaSettings'
import User from '../FillableForms/User'

interface IFillableDocumentForm {
  form: any
  open: boolean
  close: () => void
  origin: string
}

const FillableDocumentForm: React.FC<IFillableDocumentForm> = ({
  form,
  open,
  close,
  origin,
}: IFillableDocumentForm) => {
  const context = useContext<any>(BusinessContext)

  const [fillableFormData, setFillableFormData] = useState<any | null>(null)
  const [fillableForm, setFillableForm] = useState<any | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const inputDataFormSchema = getValidations(form.type)
  const [initialValues, setInitialValues] = useState<any>({})
  const [existFillableForms, setExistFillableForms] = useState<any | null>(null)
  const certificationPeriodSelected = useContext<ICertificationPeriod>(
    CertificationPeriodContext
  )
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [statusForm, setStatusForm] = useState('')
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const onCloseDialog = () => close()
  const userToken = GetAccessToken()
  const queryClient = useQueryClient()
  const { loading, setLoading } = useContext(GlobalContext)
  const [dirty, setDirty] = useState(false)
  const [files, setFiles] = useState<FilesToUpload[] | null>([])
  const ability = useContext(AbilityContext)
  const [portfolioAssetTypeFilled, setPortfolioAssetTypeFilled] =
    useState(false)
  const [openCreateUserDialog, setOpenCreateUserDialog] =
    useState<boolean>(false)
  const selectItem = (id: string) => {
    setFiles(null)
    setFillableForm(existFillableForms.find((d: any) => d.id === id))
    const jsonData = JSON.parse(
      existFillableForms.find((d: any) => d.id === id).formData
    )[0]

    const formMapped = new Map(Object.entries(jsonData))
    setFillableFormData(formMapped)
  }

  const handleRemoveFile = (section: string) => {
    if (files !== null) {
      const existFiles = files
      const fileToRemove = files.findIndex((i) => i.section === section)

      existFiles.splice(fileToRemove, 1)
      setFiles(existFiles)
    }
  }
  const removeItem = (id: string) => {
    setLoading(true)
    mutationDeleteFillableFormItem.mutate(id)
  }

  const handleRemoveFileByIndex = (index: number) => {
    if (files !== null && files.length > 1) {
      const existFiles = files

      existFiles.splice(index, 1)
      setFiles(existFiles)
    } else setFiles(null)
  }

  const handleSupportingDocument = (file: FilesToUpload) => {
    if (files === null || files.length === 0) {
      setFiles([file])
    } else if (files.findIndex((i) => i.section === file.section) === -1) {
      setFiles([...files, file])
    } else {
      const existFiles = files
      const fileToRemove = files.findIndex((i) => i.section === file.section)

      existFiles.splice(fileToRemove, 1)

      if (existFiles) {
        existFiles.push(file)
      }

      setFiles(existFiles)
    }
  }

  const handleSupportingDocuments = (file: FilesToUpload[]) => {
    setFiles(file)
  }

  const inputDataForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: inputDataFormSchema,
    validateOnChange: true,

    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setDirty(false)
      mutationSubmitFillableForm.mutate()
    },
  })

  const handleFormFilled = (value: any) => {
    setPortfolioAssetTypeFilled(value)
  }

  const handleChange = (name: any, value: any, setValue: boolean = false) => {
    setInitialValues({ ...initialValues, [name]: value })

    if (setValue) {
      inputDataForm.setFieldValue(name, value, true)
    }
    setDirty(true)
  }

  const handleOnBlur = (name: any, value: any) => {
    inputDataForm.setFieldValue(name, value, true)
    setDirty(true)
  }

  const mutationRemoveSupportingDocument = useMutation({
    mutationFn: async (id: number) => {
      return axios({
        method: 'put',
        url: `${BACKEND_URL}/FillableDocument/DeleteSupportingDocument/${id}`,
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async (data) => {
      enqueueSnackbar('Document deleted', notistackSucces)

      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getFillableForm' })
      setLoading(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setLoading(false)
    },
  })

  const handleRemoveSupportingDocument = (id: number) => {
    mutationRemoveSupportingDocument.mutate(id)
  }

  const mutationSubmitFillableForm = useMutation({
    mutationFn: async () => {
      const formData = new FormData()
      if (files && files.length > 0) {
        const baseKey = 'files'

        files.forEach((f: FilesToUpload, idx) => {
          formData.append(`${baseKey}[${idx}].Section`, f.section)
          formData.append(`${baseKey}[${idx}].File`, f.file, f.file.name)
        })
      }

      const requestData = {
        businessId: context.id,
        businessName: context.name,
        businessTypeName: context.type,
        id: fillableForm !== null ? fillableForm.id : null,
        type: form.type,
        formName: form.name,
        fillableFormId: form.id,
        certificationPeriodId: certificationPeriodSelected.id,
        supportingDocuments:
          fillableForm !== null ? fillableForm.supportingDocuments : null,
      }
      formData.append('requestData', JSON.stringify(requestData))

      if (form.type === 'business-information') {
        const values = initialValues
        if (initialValues.samePhysicalAddress) {
          values.mailingAddress = values.physicalAddress
          values.mailingCity = values.city
          values.mailingState = values.state
          values.mailingZipcode = values.zipcode
        }

        if (initialValues.samePhysicalAddressToBilling) {
          values.billingAddress = values.physicalAddress
          values.billingCity = values.city
          values.billingState = values.state
          values.billingZipcode = values.zipcode
        }

        formData.append('formDataValues', `[${JSON.stringify(values)}]`)
      } else if (form.type === 'information-security') {
        const values = initialValues

        if (initialValues.hadPreviousSecurityAssessments !== '1')
          delete values.lastDateAssessment

        if (initialValues.duration !== 'Other') delete values.otherDuration

        formData.append('formDataValues', `[${JSON.stringify(values)}]`)
      } else if (
        form.type === 'portfolio-asset-types' ||
        form.type === 'media-settings'
      ) {
        formData.append(
          'formDataValues',
          `[${JSON.stringify(inputDataForm.values)}]`
        )
      } else {
        formData.append('formDataValues', `[${JSON.stringify(initialValues)}]`)
      }
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/FillableDocument`,

        data: formData,
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Document filled.', notistackSucces)
      setFiles(null)
      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getFillableForm' })
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    },
  })

  const mutationCreateUsers = useMutation({
    mutationFn: async () => {
      const users = existFillableForms.map(
        (item: any) => JSON.parse(item.formData)[0]
      )
      const requestData = {
        businessId: context.id,
        users: JSON.stringify(users),
      }
      await createUsersAndRoles(requestData.businessId, requestData.users)
    },
    onSuccess: async () => {
      enqueueSnackbar('Users created.', notistackSucces)
      setLoading(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setLoading(false)
    },
  })

  const getFillableDocument = useQuery({
    queryKey: ['getFillableForm', form.type, context.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/fillabledocument`,
        params: {
          businessId: context.id,
          formType: form.type,
          certificationPeriodId: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setLoading(false)
        return result.data
      })
    },
    cacheTime: 0,
  })

  const fillableFormQueryData = getFillableDocument.data

  useEffect(() => {
    if (fillableFormQueryData) {
      if (fillableFormQueryData.length > 0) {
        let index = 0
        if (localStorage.getItem('executiveSelected')) {
          index =
            fillableFormQueryData.findIndex(
              (f: any) =>
                f.id.toString() === localStorage.getItem('executiveSelected')
            ) ?? 0
        }

        setFillableForm(fillableFormQueryData[index])
        const jsonData = JSON.parse(fillableFormQueryData[index].formData)[0]
        const testMap = new Map(Object.entries(jsonData))
        setFillableFormData(testMap)
      }
      setExistFillableForms(fillableFormQueryData)
    }
  }, [fillableFormQueryData])

  useEffect(() => {
    if (existFillableForms) {
      StatusForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existFillableForms])

  const CreateUsers = () => {
    setLoading(true)
    mutationCreateUsers.mutate()
  }

  const resetForm = () => {
    if (fillableFormData && fillableForm) {
      fillableFormData.forEach((value: any, key: string) => {
        inputDataForm.setFieldValue(key, null, false)
      })

      setInitialValues({})
      setFillableForm(null)
    } else {
      setInitialValues({})
      setFillableForm(null)
    }
  }

  const mutationDeleteFillableFormItem = useMutation({
    mutationFn: async (id: string) => {
      return axios({
        method: 'put',
        url: `${BACKEND_URL}/FillableDocument/DeleteFormItem/${id}`,
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async (data) => {
      const typeLabelMap: Record<string, string> = {
        insurance: 'Insurance',
        executive: 'Executive',
        'media-settings': 'Naming convention',
        user: 'User',
      }

      enqueueSnackbar(
        `${typeLabelMap[form.type] || 'Record'} deleted.`,
        notistackSucces
      )

      resetForm()

      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getFillableForm' })
      setLoading(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setLoading(false)
    },
  })

  useEffect(() => {
    if (fillableFormData && fillableForm) {
      fillableFormData.forEach((value: any, key: string) => {
        inputDataForm.setFieldValue(key, value, false)
      })

      setInitialValues(JSON.parse(fillableForm.formData)[0])
    } else if (
      form.type === 'business-information' &&
      inputDataForm.values.samePhysicalAddress === undefined
    ) {
      setInitialValues({ ...initialValues, samePhysicalAddress: true })
      inputDataForm.setFieldValue('samePhysicalAddress', true, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillableFormData])

  const canManage =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageCertification,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageFiles,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
      'any'
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_External,
      'any'
    )

  const canView =
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    ) && !canManage

  const readOnly =
    certificationPeriodSelected.id !== null ||
    (form.type === 'disclosure-authorization' && isInternal) ||
    canView

  const canInternalSave = certificationPeriodSelected.id === null && isInternal

  const StatusForm = () => {
    setStatusForm(
      !existFillableForms ||
        existFillableForms.length === 0 ||
        existFillableForms.findIndex(
          (w: any) => w.status === 'Pending Upload'
        ) !== -1
        ? 'Pending Submission'
        : existFillableForms.findIndex(
            (e: any) => e.status === 'Pending Acceptance'
          ) !== -1
        ? 'Pending Acceptance'
        : 'Accepted'
    )
  }

  if (statusForm === '') return <></>

  return (
    <>
      <Dialog
        open={open}
        maxWidth={
          ['insurance', 'media-settings'].includes(form.type) ? 'lg' : 'md'
        }
        onClose={() => onCloseDialog()}
        className="documentDialog"
      >
        {loading && (
          <Box>
            <div className="k-loading-mask">
              <span className="k-loading-text">Loading</span>
              <div className="k-loading-image" />
              <div className="k-loading-color" />
            </div>
          </Box>
        )}
        <Box>
          <>
            <DialogTitleStyled id="comment-title">
              <Typography
                variant="body1"
                style={{
                  color: 'white',
                  fontSize: '1.5rem',
                  fontWeight: 400,
                  lineHeight: 1.2,
                  textAlign: 'center',
                }}
              >
                {form.name}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={onCloseDialog}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                }}
              >
                <Close />
              </IconButton>
            </DialogTitleStyled>
            <DialogContent
              style={{
                width:
                  form.type === 'insurance'
                    ? '1035px'
                    : form.type === 'media-settings'
                    ? '1085px'
                    : '',
              }}
            >
              <form onSubmit={inputDataForm.handleSubmit}>
                <Box>
                  <Box p={4}> Status: {statusForm}</Box>

                  {form.type === 'executives' && (
                    <Executives
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      selectItem={selectItem}
                      fillableForms={existFillableForms}
                      selectedItem={fillableForm || null}
                      readOnly={readOnly}
                      deleteFormItem={removeItem}
                      origin={origin}
                    />
                  )}
                  {form.type === 'user' && (
                    <User
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      selectItem={selectItem}
                      fillableForms={existFillableForms}
                      selectedItem={fillableForm || null}
                      readOnly={readOnly}
                      deleteFormItem={removeItem}
                      origin={origin}
                    />
                  )}
                  {form.type === 'compliance-request' && (
                    <CollectionAgencyManager
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'compliance-legal-regulatory' && (
                    <ComplianceLegalRegulatory
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                      selectedItem={fillableForm || null}
                      setSupportingDocument={handleSupportingDocument}
                      filesUploaded={files}
                      status={statusForm}
                      handleRemoveFile={handleRemoveFile}
                      dirty={dirty}
                    />
                  )}
                  {form.type === 'bank-account' && (
                    <BankAccount
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'business-information' && (
                    <Businessinformation
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'disclosure-authorization' && (
                    <DisclosureAuthorization
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'information-security' && (
                    <InformationSecurity
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'insurance' && (
                    <Insurance
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      handleBlur={handleOnBlur}
                      selectItem={selectItem}
                      fillableForms={existFillableForms}
                      selectedItem={fillableForm || null}
                      readOnly={readOnly}
                      setSupportingDocuments={handleSupportingDocuments}
                      filesUploaded={files}
                      handleRemoveFile={handleRemoveFileByIndex}
                      handleRemoveSupportingDocument={
                        handleRemoveSupportingDocument
                      }
                      deleteFormItem={removeItem}
                    />
                  )}
                  {form.type === 'references' && (
                    <References
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'company-registrations-trading-names' && (
                    <CompanyRegistrationsTradingNames
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                    />
                  )}
                  {form.type === 'portfolio-asset-types' && (
                    <PortfolioAssetTypes
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      readOnly={readOnly}
                      handleFormFilled={handleFormFilled}
                    />
                  )}
                  {form.type === 'media-settings' && (
                    <MediaSettings
                      handleChange={handleChange}
                      inputDataForm={inputDataForm}
                      selectItem={selectItem}
                      fillableForms={existFillableForms}
                      selectedItem={fillableForm || null}
                      readOnly={readOnly}
                      deleteFormItem={removeItem}
                    />
                  )}
                </Box>
              </form>
            </DialogContent>
            <DialogActions>
              <>
                {!readOnly && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onCloseDialog}
                    size="small"
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                )}

                {!readOnly && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      loading ||
                      (form.type === 'portfolio-asset-types' &&
                        !portfolioAssetTypeFilled)
                    }
                    onClick={inputDataForm.submitForm}
                  >
                    Submit
                  </Button>
                )}

                {canInternalSave && fillableForm !== null && (
                  <FillableActions
                    formType={form.type}
                    status={fillableForm.status}
                    fillableFormId={fillableForm.id}
                    loading={loading}
                    inputDataForm={inputDataForm}
                    isDirty={dirty}
                    canInternalSave
                  />
                )}

                {!readOnly &&
                  fillableForm !== null &&
                  [
                    'user',
                    'executives',
                    'insurance',
                    'media-settings',
                  ].includes(form.type) && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={resetForm}
                      disabled={loading}
                    >
                      Add {form.name}
                    </Button>
                  )}

                {form.type === 'user' &&
                  canInternalSave &&
                  statusForm === 'Accepted' && (
                    <Button
                      onClick={() => setOpenCreateUserDialog(true)}
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      size="small"
                    >
                      Create Users
                    </Button>
                  )}
              </>
            </DialogActions>
          </>
        </Box>
      </Dialog>
      <Dialog
        open={openCreateUserDialog}
        onClose={() => setOpenCreateUserDialog(false)}
        aria-labelledby="create-user-dialog"
        aria-describedby="create-user-dialog-description"
        maxWidth="md"
        PaperProps={{ style: { width: '500px' } }}
      >
        <DialogTitleStyled id="create-user-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Create User
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenCreateUserDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography>Are you sure you want to create the users?</Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpenCreateUserDialog(false)
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenCreateUserDialog(false)
              CreateUsers()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FillableDocumentForm
