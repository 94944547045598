import { authUrl, clientId } from 'src/configs/authConst'

export const APP_NAME = 'Comply'

export const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const DISPLAY_NAME = 'http://debttrader.com/claims/display_name'
export const PERMISSION_INFO = 'http://debttrader.com/claims/permission_info'
export const BUSINESS_INFO = 'http://debttrader.com/claims/business_info'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const EMAIL = 'http://debttrader.com/claims/user_email'

export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'

export const USER_LOCAL = `dt.user:${authUrl}:${clientId}`
export enum AccountPortfolioType {
  ChargeOffUSA = 1,
  Bankruptcy = 2,
}

export const PERMISSION_INFO_002 =
  'http://debttrader.com/claims/permission_info/002'

export const MARKETPLACE_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/000'

export const CONTROL_PANEL_PERMISSION_TOKEN =
  'http://debttrader.com/claims/permission_info/001'

export enum PermissionCodeAccess {
  ComplianceManagementSystem = '002',
  ComplianceManagementSystem_Basic = '002.000',
  ComplianceManagementSystem_Basic_ManageFiles = '002.000.000',
  ComplianceManagementSystem_Basic_External = '002.000.001',
  ComplianceManagementSystem_Basic_Internal = '002.000.002',
  ComplianceManagementSystem_Basic_DocumentSetupView = '002.000.003',
  ComplianceManagementSystem_Basic_ManageDocumentSetup = '002.000.004',
  ComplianceManagementSystem_Basic_DeleteDocument = '002.000.005',
  ComplianceManagementSystem_Basic_ManageCertification = '002.000.006',
  ComplianceManagementSystem_Basic_RepositoryAccess = '002.000.007',
  ComplianceManagementSystem_Basic_ViewAccess = '002.000.008',
  ComplianceManagementCorporateSOP = '002.001',
  ComplianceManagementCorporateSOP_Access = '002.001.000',
  ComplianceManagementCorporateSOP_Redline = '002.001.001',
  ComplianceManagementCorporateSOP_Approve = '002.001.002',
  ComplianceManagementCorporateSOP_Upload = '002.001.003',
  ComplianceManagementCorporateSOP_Archive = '002.001.004',
  ComplianceManagementCorporateSOP_Download = '002.001.005',
}

export const MaxFileSize = 204857600
