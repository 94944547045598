import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { maskPhoneNumber } from 'src/utils/masker'
import { useQuery } from 'react-query'
import { getRolesToUser } from 'src/api/operations/get/permission'
import { Icon } from 'everchain-uilibrary'
import { ConfirmDialog } from '../Dialogs'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'

interface IUser {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  selectItem: (id: string) => void
  fillableForms: any
  selectedItem: any
  readOnly: boolean
  deleteFormItem: (id: string) => void
  origin: string
}

const User: React.FC<IUser> = ({
  handleChange,
  inputDataForm,
  selectItem,
  fillableForms,
  selectedItem,
  readOnly,
  deleteFormItem,
  origin,
}: IUser) => {
  const { profileClient } = useContext(AuthContext)

  const [userToRemove, setUserToRemove] = useState('')

  const [rolesSelected, setRolesSelected] = useState<any[]>([])

  const [openDeleteUserDialog, setOpenDeleteUserDialog] =
    useState<boolean>(false)

  const getRolesToUserQuery = useQuery({
    queryKey: ['getRolesToUser'],
    cacheTime: 0,
    queryFn: async () => {
      const result = await getRolesToUser()
      return result
    },
  })
  const rolesData = getRolesToUserQuery?.data

  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const DeleteItemForm = (id: string) => {
    setOpenDeleteUserDialog(false)
    deleteFormItem(id)
  }

  const getAvailableRoles = () => {
    const available = rolesData?.roles
      ?.filter(
        (item: any) =>
          !inputDataForm.values.roles?.some((x: any) => x.id === item.id)
      )
      .map((x: any) => {
        return {
          id: x.id,
          name: x.name,
          displayName: `${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderRoles = () => {
    const handleChangeRoles = (value: any) => {
      setRolesSelected(value)
      handleChange('roles', value, true)
    }

    const availableRoles = rolesData ? getAvailableRoles() : []
    return (
      <MultipleSelectionDropDown
        id="input-userroles"
        label="Roles"
        data={availableRoles}
        disable={false}
        selectionState={rolesSelected}
        handleSelectionChange={handleChangeRoles}
        displayProperties={['name']}
        hasValidationError={!!inputDataForm.errors.roles}
        errorMessage={inputDataForm.errors.roles}
      />
    )
  }

  useEffect(() => {
    setRolesSelected(
      inputDataForm.values.roles?.length === undefined
        ? []
        : inputDataForm.values.roles
    )
  }, [inputDataForm])

  return (
    <>
      {!fillableForms ? (
        <Box display="flex" flexDirection="row" width="60em" />
      ) : (
        <Box display="flex" flexDirection="row" width="60em">
          <List
            style={{ minWidth: '220px' }}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Users Submitted
              </ListSubheader>
            }
          >
            {fillableForms &&
              fillableForms.map((x: any) => (
                <ListItem
                  key={x.id}
                  selected={
                    x.id ===
                    (selectedItem && selectedItem?.id !== null
                      ? selectedItem?.id
                      : null)
                  }
                >
                  <Box
                    style={{ width: '100%' }}
                    borderColor="blue"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                      >
                        <Tooltip title={x.status}>
                          {x.status === 'Accepted' ? (
                            <Icon name="Check" fontSize="small" color="green" />
                          ) : x.status === 'Pending Upload' ? (
                            <Icon name="Close" fontSize="small" color="red" />
                          ) : (
                            <Icon
                              name="ErrorOutline"
                              fontSize="small"
                              color="gold"
                            />
                          )}
                        </Tooltip>
                      </Box>
                      <Box
                        style={{ cursor: 'pointer' }}
                        ml={2}
                        onClick={() => {
                          selectItem(x.id)
                          localStorage.setItem('userSelected', x.id)
                        }}
                      >
                        {`${JSON.parse(x.formData)[0].firstName} ${
                          JSON.parse(x.formData)[0].lastName
                        }`}{' '}
                      </Box>
                    </Box>
                    {x.status === 'Pending Acceptance' && (
                      <Box ml={2}>
                        <Tooltip title="Remove user.">
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setUserToRemove(x.id)
                              setOpenDeleteUserDialog(true)
                            }}
                          >
                            <DeleteIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </ListItem>
              ))}
          </List>

          <Grid
            container
            direction="row"
            style={{ margin: '6px 6px 6px 6px', width: '800px' }}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-firstName"
                label="First Name"
                name="firstName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.firstName || ''}
                error={!!inputDataForm.errors.firstName}
                helperText={inputDataForm.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-lastname"
                label="Last Name"
                name="lastName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.lastName || ''}
                error={!!inputDataForm.errors.lastName}
                helperText={inputDataForm.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-displayName"
                label="Display Name"
                name="displayName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.displayName || ''}
                error={!!inputDataForm.errors.displayName}
                helperText={inputDataForm.errors.displayName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-title"
                label="Title"
                name="title"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.title || ''}
                error={!!inputDataForm.errors.title}
                helperText={inputDataForm.errors.title}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-voicePhoneNumber"
                label="Voice Phone Number"
                name="voicePhoneNumber"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, country)
                  handleChange(name, masked, true)
                }}
                value={inputDataForm.values.voicePhoneNumber || ''}
                error={!!inputDataForm.errors.voicePhoneNumber}
                helperText={inputDataForm.errors.voicePhoneNumber}
                placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-textPhoneNumber"
                label="Text Messaging Phone Number"
                name="textMessagingPhoneNumber"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, country)
                  handleChange(name, masked, true)
                }}
                value={inputDataForm.values.textMessagingPhoneNumber || ''}
                error={!!inputDataForm.errors.textMessagingPhoneNumber}
                helperText={inputDataForm.errors.textMessagingPhoneNumber}
                placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-emailAddress"
                label="Email Address"
                name="emailAddress"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, true)
                }}
                value={inputDataForm.values.emailAddress || ''}
                error={!!inputDataForm.errors.emailAddress}
                helperText={inputDataForm.errors.emailAddress}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {renderRoles()}
            </Grid>
          </Grid>
        </Box>
      )}

      {openDeleteUserDialog && (
        <ConfirmDialog
          open={openDeleteUserDialog}
          title="Remove User"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to delete this user?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteUserDialog(false)}
          onConfirm={() => DeleteItemForm(userToRemove)}
        />
      )}
    </>
  )
}

export default User
