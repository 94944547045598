import { Colors } from 'everchain-uilibrary'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    html, body {
        font-size: 0.9rem;
    }
    body {
      line-height: 1rem;
      text-transform: none;
      font-family: 'Roboto', sans-serif;
      font-size: 0.9rem;
    }
    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    @media(min-width: 768px) {
      html {
        font-size: 0.9rem;
      }
    }
    @media(min-width: 1281px) {
      html {
        font-size: 0.9rem;
      }
    }
    .k-grid { line-height: 1 }

    .acceptBidButton:hover
    {
      color: ${Colors.primary};
    }
    th.k-header.active > div > div {
      color: ${Colors.white};
      background-color: ${Colors.primary};
    }

    .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-desc-sm, .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-asc-sm {
      color: ${Colors.white};
    }

    .k-grid-header {
      color: ${Colors.white};
      background-color: ${Colors.primary};
      }
      .k-grid-header .k-column-title :hover{
      color: ${Colors.white};
      }
      .k-grid-header .k-header * .k-link {
        color: ${Colors.white};
        font-size: 0.9rem;
        padding: 7px 18px 7px 8px;
        line-height: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.01071em;
      }
      .k-grid-header .k-header * .k-link .k-svg-icon { 
          color: ${Colors.white};
      }
      .k-grid-header th,
      .k-grid-content td,
      .k-grid .k-table-td {
        border-left: 1px solid ${Colors.gray};
      }
      tr:hover td {
        background-color: ${Colors.secondary};
      }
      .k-grid td {
        padding: 10px 16px 7px 8px;
        //padding: 10px 16px ;
        font-size: 0.9rem;
        line-height: 1.3;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
      .k-grid-header-wrap > table {
        width: 100% !important;
      }
      .k-grid-content table {
        width: 100% !important;
      }
      .k-svg-icon {
        width: 14px;
        height: 14px;
      }
      span.k-svg-icon.k-svg-i-more-vertical {
        color: ${Colors.white};
      }
      span.k-svg-icon.k-i-sort-desc-sm{
        color: ${Colors.white};
      }
      .k-text-right {
        text-align: right;
      }
      th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
        padding: 8px 6px 8px 6px;
      }    
      .MuiTableCell-sizeSmall {
        padding: 8px 7px 8px 7px;
      }

      .k-table td {
        padding: 7px;
      }
  
      .k-table-thead {
        background-color: transparent;
        color: ${Colors.white};
        font-size: 13px;
      }
  
      .k-table-row {
        font-size: 13px;
      }
  
      .k-cell-inner {
        font-weight: 500;
        letter-spacing: 0.02em;
      }
     .k-grid-header th {
        padding: 7px 25px 7px 10px !important;
        vertical-align: middle !important;
      }
      .k-grid-header:hover,
        .k-grid-header .k-sorted,
        .k-grid-header-menu,
        .k-grid-header .k-sort-icon {
          color: ${Colors.white};
      }
  
      .k-sort-icon .k-svg-icon{
        margin-top: -2px;
      }
  
      .k-grid-header-menu {
        margin: 0 !important;
      }
  
      .k-grid-header-menu:focus {
        color: ${Colors.white} !important;
      }
  
      .k-grid tbody tr:hover {
      background: ${Colors.secondary};
      }
  
      .k-detail-row {
        background: ${Colors.white} !important;
      } 
  
      .k-grid .k-detail-row .k-detail-cell {
        padding: 0;
      }
  
      .k-detail-row .k-grid-header tr:hover  {
        background: ${Colors.primary};
      } 
  
     .k-pager-numbers .k-button:hover {
        color: ${Colors.blue};
        background-color: ${Colors.secondary};
      }
      .k-pager-numbers .k-button {
        color: ${Colors.blue};
      }
    .k-animation-container {
        z-index: 10003;
        &.k-animation-container-relative {
          overflow: initial;
        }
      }

      .popup-content {    
        border-radius: 4px;      
       }

      .wrapper-content {
        box-shadow: 0px 1px 1px  rgb(235 235 235), 1px 2px 3px  rgb(240 240 240), 2px 2px 3px  rgb(245 245 245);
        border-radius: 4px;   
      }


    .k-grid-header-wrap ,
    .k-grid-header-wrap  th.k-header {
      background: ${Colors.primary};
      color: ${Colors.white};
      .k-button:hover {
        color: #f3fafe; 
      }
    }
    .k-grid tbody tr:hover {
    background: ${Colors.secondary};
    }
    .k-pager-numbers .k-button:hover {
      color: ${Colors.primary};
      background-color: ${Colors.secondary};
    }
    .k-pager-numbers .k-button {
      color: ${Colors.primary};
    }
    .k-pager-numbers .k-button.k-selected {
      color: ${Colors.white};
      background-color: ${Colors.blue} !important;
    }
    .k-picker-solid {
      background-color: ${Colors.white};
    }
    .MuiPaginationItem-textPrimary.Mui-selected {
      color: ${Colors.white};
      background-color: ${Colors.blue};
    }
    .MuiList-root.MuiList-padding {
      padding-top: 0px;
    }
    .MuiMenu-list {
      max-height: 325px;
    }
    .logo-sidebar {
    margin-right: 25px;
    }
    .MuiFormControl-marginNormal {
      width: 100%;
    }
    .MuiListItemIcon-root {
      color: ${Colors.primary};
    }

    .k-svg-i-more-vertical {
      padding-top: 0px
    }
    .k-grid-header th {
      padding: 7px 25px 7px 10px;
      vertical-align: middle !important;
    }

    .k-loading-mask {
        color: ${Colors.primary};
    }

    .MuiTab-root {
     text-transform: uppercase;
    }

    .MuiTabs-flexContainer {
     justify-content: space-around;
     text-transform: uppercase;
    }

    .MuiInputLabel-root {
     width: 100%;
    }
     
    .MuiTypography-root .MuiListItemText-primary .MuiTypography-body2 .MuiTypography-displayBlock {
      color: ${Colors.primary};
    }

   .MuiTypography-root .MuiTypography-caption .MuiTypography-colorTextPrimary {
    color: ${Colors.black}; 
   }

    .MuiTypography-caption {
     color: ${Colors.black} !important;  
    }      
  
   .MuiOutlinedInput-notchedOutline legend {
    font-size: 0.82em;
    }

    .custom-textfieldMultiline-label .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.82) !important;
    width: 100%;
}
`
