import { httpClient } from 'src/api/axios/api'
import {
  BusinessGetUsersByUserBusiness,
  GetBusinesses,
  GetBusinessesRelated,
  GetDepartments,
  GetInternalUsers,
} from './urls'

export const getCountry = () => {
  return `query {
    countryDataResponse: getCountry {
      countryDescription
      countryCode
      stateInfo {
        code
        description
      }
    }
  }`
}

export const getBuyerQuery = (buyerId: string) => {
  return `query {
    getBuyer(buyerId: "${buyerId}") {
      id
      businessType
      status
      name
      buyerType: bCOBuyerType
    }
  }`
}

export const getUserBusiness = async (
  userId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(BusinessGetUsersByUserBusiness, {
    params: {
      userId,
    },
  })
  return response?.data
}

export const getBusinesses = async (
  clientId: number | undefined | null
): Promise<any> => {
  const response = await httpClient.get(GetBusinesses, {
    params: {
      request: {
        clientIdFilter: clientId,
      },
    },
  })

  if (response && response.data.businesses) {
    response.data.businesses = response.data.businesses.map((business: any) => {
      return {
        ...business,
        type: business.businessType,
      }
    })
  }

  return response?.data
}

export const getBusinessesRelated = async (
  ids: string,
  businessType: string
): Promise<any> => {
  const response = await httpClient.get(GetBusinessesRelated, {
    params: {
      businesses: ids,
      businessType,
    },
  })
  return response?.data
}

export const getInternalUsers = async (): Promise<any> => {
  const response = await httpClient.get(GetInternalUsers)
  return response?.data
}

export const getDepartments = async (): Promise<any> => {
  const response = await httpClient.get(GetDepartments)
  return response?.data
}
