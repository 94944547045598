import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useFormik } from 'formik'
import axios from 'axios'
import { useQuery } from 'react-query'
import * as Yup from 'yup'
import {
  getDepartments,
  getInternalUsers,
} from 'src/api/operations/get/business'

import { MaxFileSize } from 'src/utils/constants'

import { Delete, Description } from '@mui/icons-material'
import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import UploadDragDrop from '../UploadDragDrop'
import { UploadItem } from '../UploadDragDrop/styles'

interface IUploadSOP {
  name: string | null
  department: string
  owner: string
  nextAuditDueDateUtc?: Date | null
}

interface IUploadSOPPage {
  close: () => void
}

const UploadSOP: React.FC<IUploadSOPPage> = ({ close }: IUploadSOPPage) => {
  const MAX_SIZE_FILE = MaxFileSize
  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().trim().nullable().required('Required'),
    owner: Yup.string().trim().nullable().required('Required'),
    department: Yup.string().trim().nullable().required('Required'),
    nextAuditDueDateUtc: Yup.date().nullable().required('Required'),
  })

  const currentDate = new Date()
  const nextDay = new Date(currentDate)
  const userToken = GetAccessToken()
  const { enqueueSnackbar } = useSnackbar()
  const [internalUsers, setInternalUsers] = useState<any[]>([])
  const [departments, setDepartments] = useState<any[]>([])
  const notistackSucces = notistackOptions('success')
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const [saving, setSaving] = useState(false)

  const [files, setFiles] = useState<File[] | null>(null)

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<IUploadSOP>({
    name: null,
    department: '',
    owner: '',
    nextAuditDueDateUtc: null,
  })

  useQuery({
    queryKey: ['getInternalUsers'],
    queryFn: async () => {
      const result = await getInternalUsers()

      setInternalUsers(result)
    },
    cacheTime: 0,
  })

  useQuery({
    queryKey: ['getDepartments'],
    queryFn: async () => {
      const result = await getDepartments()

      setDepartments(result)
    },
    cacheTime: 0,
  })

  const inputDataForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData()
      setSaving(true)
      files?.forEach((file: File) => {
        formData.append('files', file, file.name)
      })

      axios({
        method: 'post',
        url: `${BACKEND_URL}/corporatesop/UploadSOP`,
        data: formData,
        headers: {
          Authorization: userToken,
        },
        params: {
          name: values.name || null,
          department: values.department || null,
          departmentName: departments.find((f) => f.type === values.department)
            ?.name,
          ownerName: values.owner,
          ownerId:
            internalUsers.find((f: any) => f.DisplayName === values.owner)
              ?.Id || '',
          nextAuditDueDate: values.nextAuditDueDateUtc || null,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP uploaded.', notistackSucces)
          setFiles(null)
          inputDataForm.resetForm()
          setSaving(false)
          close()
        })
        .catch((error) => {
          enqueueSnackbar(
            'Error on uploading new SOP file.',
            notistackOptions('error')
          )
          setSaving(false)
        })

      setSubmitting(false)
    },
  })

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }

  const availableOwners: any[] =
    (inputDataForm.values.department &&
      internalUsers &&
      internalUsers.filter(
        (i: any) =>
          i.Department !== null &&
          i.Department?.toLowerCase() ===
            inputDataForm.values.department?.toLowerCase()
      )) ||
    []
  const handleUploadFileRemove = (index: number) => {
    if (files) {
      setFiles(files.filter((f) => f.name !== files[index].name))
    }
  }

  return (
    <form onSubmit={inputDataForm.handleSubmit}>
      <Box width="100%">
        <Grid container spacing={3} style={{ minWidth: '500px' }}>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={saving}
              fullWidth
              label="Department"
              name="department"
              select
              onChange={inputDataForm.handleChange}
              error={!!inputDataForm.errors.department}
              value={inputDataForm.values.department}
              helperText={<>{inputDataForm.errors.department}</>}
            >
              {departments?.map((option) => (
                <MenuItem key={option.type} value={option.type}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={saving}
              fullWidth
              label="Owner"
              name="owner"
              select
              onChange={inputDataForm.handleChange}
              error={!!inputDataForm.errors.owner}
              value={inputDataForm.values.owner}
              helperText={<>{inputDataForm.errors.owner}</>}
            >
              {availableOwners?.map((option) => (
                <MenuItem key={option.Id} value={option.DisplayName}>
                  {option.DisplayName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              disabled={saving}
              style={{ width: '100%' }}
              id="nextAuditDueDateUtc"
              label="Next Audit Due Date"
              name="nextAuditDueDateUtc"
              onChange={(date: any) => {
                inputDataForm.setFieldValue('nextAuditDueDateUtc', date, true)
              }}
              minDate={nextDay}
              value={inputDataForm.values.nextAuditDueDateUtc || null}
              errorMessage={inputDataForm.errors.nextAuditDueDateUtc?.toString()}
              country={country}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={saving}
              fullWidth
              data-cy="dialog-input-field-name"
              label="Name"
              name="name"
              inputProps={{
                maxLength: 200,
              }}
              onChange={inputDataForm.handleChange}
              value={inputDataForm.values.name || null}
              error={!!inputDataForm.errors.name}
              helperText={inputDataForm.errors.name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            {files && files.length > 0 && (
              <UploadItem>
                <Grid container>
                  <Grid item xs={12}>
                    <List
                      style={{
                        marginBottom: 2,
                        width: '100%',
                      }}
                    >
                      {files.map((file: any, idx: number) => {
                        return (
                          <ListItem alignItems="flex-start" key={`item_${idx}`}>
                            <div
                              className="upload-item-info"
                              key={`div_${idx}`}
                            >
                              <Description
                                key={`DescriptionIcon_${idx}`}
                                fontSize="small"
                                color="primary"
                                className="upload-item-icon"
                              />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="span"
                                key={`Typography_${file.name}`}
                              >
                                {file.name.length > 200
                                  ? `${file.name.substring(0, 15)}...`
                                  : file.name}
                              </Typography>
                              <IconButton
                                size="small"
                                key={`Button_${idx}`}
                                onClick={() => {
                                  handleUploadFileRemove(idx)
                                }}
                              >
                                <Delete
                                  key={`DeleteButton_${idx}`}
                                  className="upload-item-icon"
                                  fontSize="small"
                                />
                              </IconButton>
                            </div>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                </Grid>
              </UploadItem>
            )}
            {(files === null || files.length === 0) && (
              <UploadDragDrop
                linkText="Upload File"
                text=" Word files only (max. 200mb)"
                size="small"
                multiple={false}
                onDrop={(acceptedFiles: any) => {
                  handleDroppedFiles(acceptedFiles)
                }}
                maxSize={MAX_SIZE_FILE}
                onDropRejected={handleFileRejected}
                accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
              />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Button
                disabled={files === null || files.length === 0 || saving}
                data-cy="load-sop-button"
                variant="contained"
                color="primary"
                title="Upload SOP"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default UploadSOP
