import React, { useContext } from 'react'
import { Grid, TextField } from '@mui/material'

import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'

interface ICompanyRegistrationsTradingNames {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
}

const CompanyRegistrationsTradingNames: React.FC<
  ICompanyRegistrationsTradingNames
> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: ICompanyRegistrationsTradingNames) => {
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ margin: '6px 6px 6px 6px', width: '800px' }}
        spacing={3}
      >
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            data-cy="dialog-input-field-fca-registration"
            label="FCA Registration No."
            name="fcaRegistration"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.fcaRegistration}
            error={!!inputDataForm.errors.fcaRegistration}
            helperText={inputDataForm.errors.fcaRegistration}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            data-cy="dialog-input-field-fca-permission"
            label="FCA Permissions"
            name="fcaPermission"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.fcaPermission}
            error={!!inputDataForm.errors.fcaPermission}
            helperText={inputDataForm.errors.fcaPermission}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            disabled={readOnly}
            style={{ width: '100%' }}
            data-cy="dialog-input-field-dateofbirth"
            id="effectiveDate"
            label="Effective Date"
            name="effectiveDate"
            maxDate={new Date()}
            country={country}
            value={inputDataForm.values.effectiveDate || null}
            errorMessage={inputDataForm.errors.effectiveDate?.toString()}
            onChange={(date: any) => {
              handleChange('effectiveDate', date, true)
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-ico-registration"
            label="ICO Registration No."
            name="icoRegistration"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.icoRegistration}
            error={!!inputDataForm.errors.icoRegistration}
            helperText={inputDataForm.errors.icoRegistration}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-trading-names"
            label="Trading Names"
            name="tradingNames"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.tradingNames}
            error={!!inputDataForm.errors.tradingNames}
            helperText={inputDataForm.errors.tradingNames}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CompanyRegistrationsTradingNames
