import React, { useContext } from 'react'
import {
  CardHeader,
  Grid,
  TextField,
  Card,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStateDropDownName, isUkCountry } from 'src/utils/common'
import { maskPhoneNumber, maskZipCode } from 'src/utils/masker'
import { useQuery } from 'react-query'
import axios from 'axios'
import { getCountry } from 'src/api/operations/get/business'
import { GetAccessToken } from 'src/api/axios/api'
import hardcodeData from 'src/utils/hardcodeData'
import Loader from '../Loader'

interface IBankAccount {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
}

const BankAccount: React.FC<IBankAccount> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: IBankAccount) => {
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const userToken = GetAccessToken()
  const { data: countryData, isLoading: loadingCountry } = useQuery({
    queryKey: ['getCountry'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getCountry()}`,
        },
      }).then((result: any) => {
        return result.data.data.countryDataResponse
      })
    },
  })

  return (
    <>
      {loadingCountry && <Loader />}
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader title="Banking Info" />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-bankingName"
              label="Banking Name"
              name="bankingName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.bankingName}
              error={!!inputDataForm.errors.bankingName}
              helperText={inputDataForm.errors.bankingName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-accountType"
              id="option-accountType"
              select
              label="Account Type"
              style={{ width: '100%' }}
              name="accountType"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.accountType}
              error={!!inputDataForm.errors.accountType}
              helperText={inputDataForm.errors.accountType}
            >
              <MenuItem value="Checking">Checking</MenuItem>
              <MenuItem value="Saving">Saving</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-accountNumber"
              label="Account No."
              name="accountNumber"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.accountNumber}
              error={!!inputDataForm.errors.accountNumber}
              helperText={inputDataForm.errors.accountNumber}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-routingNumber"
              label="Routing No."
              name="routingNumber"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.routingNumber}
              error={!!inputDataForm.errors.routingNumber}
              helperText={inputDataForm.errors.routingNumber}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-nameAccount"
              label="Name On Account"
              name="nameAccount"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.nameAccount}
              error={!!inputDataForm.errors.nameAccount}
              helperText={inputDataForm.errors.nameAccount}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy="defaultBankAccount"
                  checked={inputDataForm.values.defaultBankAccount ?? false}
                  onChange={({ target: { value, name } }) => {
                    inputDataForm.setFieldValue(
                      name,
                      !inputDataForm.values.defaultBankAccount,
                      false
                    )
                    handleChange(
                      name,
                      !inputDataForm.values.defaultBankAccount,
                      false
                    )
                  }}
                  name="defaultBankAccount"
                  color="primary"
                />
              }
              label="Default Bank Account"
            />
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader title="Bank Address" />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-street"
              label="Street"
              name="street"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.street}
              error={!!inputDataForm.errors.street}
              helperText={inputDataForm.errors.street}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-street2"
              label="Street2"
              name="street2"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.street2}
              error={!!inputDataForm.errors.street2}
              helperText={inputDataForm.errors.street2}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-city"
              label="City"
              name="city"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.city}
              error={!!inputDataForm.errors.city}
              helperText={inputDataForm.errors.city}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-country"
              id="option-country"
              select
              label="Country"
              style={{ width: '100%' }}
              name="country"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.country}
              error={!!inputDataForm.errors.country}
              helperText={inputDataForm.errors.country}
            >
              {countryData?.map((option: any) => (
                <MenuItem key={option.countryCode} value={option.countryCode}>
                  {option.countryDescription}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-state"
              id="option-state"
              select
              label={getStateDropDownName(country)}
              style={{ width: '100%' }}
              name="state"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.state}
              error={!!inputDataForm.errors.state}
              helperText={inputDataForm.errors.state}
            >
              {countryData
                ?.find(
                  (x: any) => x.countryCode === inputDataForm.values.country
                )
                ?.stateInfo.map((option: any) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.description}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-bankPhone"
              label="Bank Phone"
              name="bankPhone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.bankPhone}
              error={!!inputDataForm.errors.bankPhone}
              helperText={inputDataForm.errors.bankPhone}
              placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-postalCode"
              label="Postal Code"
              name="postalCode"
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(value, country)
                handleChange(name, masked, false)
              }}
              value={inputDataForm.values.postalCode}
              error={!!inputDataForm.errors.postalCode}
              helperText={inputDataForm.errors.postalCode}
            />
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '16px' }}>
        <CardHeader title="EC Options" />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="option-status"
              data-cy="option-status"
              label="Status"
              name="status"
              select
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.status}
              error={!!inputDataForm.errors.status}
              helperText={inputDataForm.errors.status}
            >
              {hardcodeData.getDefaultStatus().map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              data-cy="option-billingDepartment"
              id="optionBillingDepartment"
              select
              label="Send Invoice Directly to Billing Department?"
              style={{ width: '100%' }}
              name="optionBillingDepartment"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionBillingDepartment}
              error={!!inputDataForm.errors.optionBillingDepartment}
              helperText={inputDataForm.errors.optionBillingDepartment}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Grid>
          {inputDataForm.values.optionBillingDepartment === 'Yes' && (
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="billingEmailAddress"
                label=" Billing Department Email"
                name="billingEmailAddress"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.billingEmailAddress}
                error={!!inputDataForm.errors.billingEmailAddress}
                helperText={inputDataForm.errors.billingEmailAddress}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  )
}

export default BankAccount
