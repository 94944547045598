import { Box } from '@mui/material'
import { State } from '@progress/kendo-data-query'
import React, { useRef, useState } from 'react'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import axios from 'axios'
import { renderDate, renderJson } from 'src/utils/formatKendoColumns'
import { AuditData } from 'src/api/models/Common'
import Loader from '../Loader'

interface IAudit {
  origin: string
}

const Audit: React.FC<IAudit> = ({ origin }: IAudit) => {
  const grid = useRef<any>(null)
  const [auditData, setAuditData] = useState<AuditData>()
  const userToken = GetAccessToken()
  const [loading, setLoading] = useState<boolean>(true)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const columns: any[] = [
    { field: 'tableName', title: 'Table', show: true, width: '200px' },
    {
      field: 'values',
      title: 'Values',
      show: true,
      cell: renderJson,
    },
    { field: 'user', title: 'User', show: true, width: '200px' },
    {
      field: 'date',
      title: 'Date',
      show: true,
      cell: renderDate,
      width: '150px',
    },
    { field: 'action', title: 'Action', show: true, width: '150px' },
  ]

  useQuery({
    queryKey: ['GetChanges', gridState],
    queryFn: async () => {
      axios({
        method: 'get',
        url: `${BACKEND_URL}/Audit/GetChanges`,
        params: {
          pagination: encodeURIComponent(JSON.stringify(gridState)),
          origin,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setAuditData(result.data)
        setLoading(false)
      })
    },
    cacheTime: 0,
  })

  return (
    <>
      <Box>
        {loading && <Loader />}
        <Box>
          <KendoGrid
            ref={grid}
            scrollable="scrollable"
            data={auditData?.changes ?? []}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            sortable
            pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
            total={auditData?.total ?? 0}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
            style={{ height: '600px' }}
          >
            {columns.reduce((acc, item, idx) => {
              const component = [...acc]

              component.push(
                <KendoGridColumn
                  key={item.field + item.title}
                  field={item.field}
                  title={item.title}
                  cell={item.cell}
                  width={item.width}
                  columnMenu={ColumnMenu}
                />
              )

              return component
            }, [])}
          </KendoGrid>
        </Box>
      </Box>
    </>
  )
}

export default Audit
