import React, { useContext } from 'react'
import { Card, CardHeader, Grid, MenuItem, TextField } from '@mui/material'

import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { DatePicker } from 'everchain-uilibrary'

interface IInformationSecurity {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
}

const InformationSecurity: React.FC<IInformationSecurity> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: IInformationSecurity) => {
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  return (
    <>
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader
          title="Security Measures Questionnaire"
          subheader="Please answer the following questions related to information, data and physical security."
        />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="had-previous-security-assessments"
              id="hadPreviousSecurityAssessments"
              select
              label="Had previous security assessments?"
              style={{ width: '100%' }}
              name="hadPreviousSecurityAssessments"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              value={inputDataForm.values.hadPreviousSecurityAssessments}
              error={!!inputDataForm.errors.hadPreviousSecurityAssessments}
              helperText={inputDataForm.errors.hadPreviousSecurityAssessments}
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              disabled={
                readOnly ||
                inputDataForm.values.hadPreviousSecurityAssessments !== '1'
              }
              data-cy="dialog-input-field-lastDateAssessment"
              id="lastDateAssessment"
              label="Date of last security assessment"
              name="lastDateAssessment"
              value={inputDataForm.values.lastDateAssessment || null}
              errorMessage={inputDataForm.errors.lastDateAssessment?.toString()}
              country={country}
              onChange={(date: any) => {
                handleChange('lastDateAssessment', date, true)
              }}
              maxDate={new Date()}
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="lastAuditCompany"
              data-cy="dialog-input-field-lastAuditCompany"
              label="Company who performed your last audit"
              name="lastAuditCompany"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.lastAuditCompany}
              error={!!inputDataForm.errors.lastAuditCompany}
              helperText={inputDataForm.errors.lastAuditCompany}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="frequencyAssessment"
              data-cy="dialog-input-field-frequencyAssessment"
              label="Frequency Assessments"
              name="frequencyAssessment"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.frequencyAssessment}
              error={!!inputDataForm.errors.frequencyAssessment}
              helperText={inputDataForm.errors.frequencyAssessment}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-acknowlodge-security-policies-dropdown"
              id="optionAcknowlodgeSecurityPolicies"
              select
              label="Are all employees required to acknowledge the company's security policies in writing?"
              style={{ width: '100%' }}
              name="optionAcknowlodgeSecurityPolicies"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              value={inputDataForm.values.optionAcknowlodgeSecurityPolicies}
              error={!!inputDataForm.errors.optionAcknowlodgeSecurityPolicies}
              helperText={
                inputDataForm.errors.optionAcknowlodgeSecurityPolicies
              }
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-secure-socket-layers-dropdown"
              id="optionSecureSocketLayers"
              select
              label="Are Secure Socket Layers (SSL) in use on your site?"
              style={{ width: '100%' }}
              name="optionSecureSocketLayers"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              value={inputDataForm.values.optionSecureSocketLayers}
              error={!!inputDataForm.errors.optionSecureSocketLayers}
              helperText={inputDataForm.errors.optionSecureSocketLayers}
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-encrypt-data-dropdown"
              id="optionEncryptData"
              select
              label="Do you encrypt data on your servers?"
              style={{ width: '100%' }}
              name="optionEncryptData"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              value={inputDataForm.values.optionEncryptData}
              error={!!inputDataForm.errors.optionEncryptData}
              helperText={inputDataForm.errors.optionEncryptData}
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="bitEncryption"
              data-cy="dialog-input-field-bitEncryption"
              label="What bit encryption is in use? (if any)"
              name="bitEncryption"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.bitEncryption}
              error={!!inputDataForm.errors.bitEncryption}
              helperText={inputDataForm.errors.bitEncryption}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-server-housed-dropdown"
              id="optionServerHoused"
              select
              label="Are your servers hosted internally or externally?"
              style={{ width: '100%' }}
              name="optionServerHoused"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              value={inputDataForm.values.optionServerHoused}
              error={!!inputDataForm.errors.optionServerHoused}
              helperText={inputDataForm.errors.optionServerHoused}
            >
              <MenuItem value="Internally">Internally</MenuItem>
              <MenuItem value="Externally">Externally</MenuItem>
              <MenuItem value="Both">Both</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-secure-ftp-dropdown"
              id="optionSecureFTP"
              select
              label="Do you currently use a Secure FTP for data transfer?"
              style={{ width: '100%' }}
              name="optionSecureFTP"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionSecureFTP}
              error={!!inputDataForm.errors.optionSecureFTP}
              helperText={inputDataForm.errors.optionSecureFTP}
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              data-cy="dialog-option-pci-complaint-dropdown"
              id="optionPCIComplaint"
              select
              label={`Is your ${
                isUk ? 'organisation' : 'organization'
              } PCI Compliant?`}
              style={{ width: '100%' }}
              name="optionPCIComplaint"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.optionPCIComplaint}
              error={!!inputDataForm.errors.optionPCIComplaint}
              helperText={inputDataForm.errors.optionPCIComplaint}
            >
              <MenuItem value="0">No</MenuItem>
              <MenuItem value="1">Yes</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              fullWidth
              id="level"
              data-cy="dialog-input-field-level"
              label="Level"
              name="level"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.level}
              error={!!inputDataForm.errors.level}
              helperText={inputDataForm.errors.level}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={readOnly}
              id="duration"
              data-cy="dialog-input-field-duration"
              select
              label="What is the duration of time that consumer data is held on your servers?"
              fullWidth
              name="duration"
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, true)
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={inputDataForm.values.duration}
              error={!!inputDataForm.errors.duration}
              helperText={inputDataForm.errors.duration}
            >
              <MenuItem value="Quarterly">Quarterly</MenuItem>
              <MenuItem value="Biannually">Biannually</MenuItem>
              <MenuItem value="Annually">Annually</MenuItem>
              <MenuItem value="Infinitely">Infinitely</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Grid>
          {inputDataForm.values.duration === 'Other' && (
            <Grid item xs={12} md={6}>
              <TextField
                disabled={readOnly}
                fullWidth
                id="otherDuration"
                data-cy="dialog-input-field-other-duration"
                label="Other duration"
                name="otherDuration"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.otherDuration}
                error={!!inputDataForm.errors.otherDuration}
                helperText={inputDataForm.errors.otherDuration}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  )
}

export default InformationSecurity
