import { httpClient } from 'src/api/axios/api'
import { CreateUsers, CreateUsersAndRoles } from './urls'

export const updateUserType = (userType: string) => {
  return (type: string): void => {
    localStorage.setItem('userType', type)
  }
}

export const createUsers = async (
  businessId: string,
  users: string
): Promise<any> => {
  const response = await httpClient.post(CreateUsers, {
    users,
    businessId,
  })
  return response?.data
}

export const createUsersAndRoles = async (
  businessId: string,
  users: string
): Promise<any> => {
  const response = await httpClient.post(CreateUsersAndRoles, {
    users,
    businessId,
  })
  return response?.data
}
