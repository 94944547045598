import React, { useContext, useState } from 'react'

import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Divider,
  Box,
  CardContent,
  IconButton,
  Tooltip,
  MenuItem,
  Menu,
} from '@mui/material'
import { BACKEND_URL } from 'src/api/axios/api'
import axios from 'axios'
import { BusinessContext } from 'src/context/BusinessContext'
import { GetAccessToken } from 'src/api/axios/helper'
import { formatDateAndTime } from 'src/utils/date'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { ICertificationPeriod } from 'src/api/models'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { AuthContext } from 'src/context/AuthenticationContext'
import { SellerSurveyDialog } from 'everchain-digital-forms'
import { notistackOptions } from 'src/configs/notistackOptions'
import { SurveyGetSurveyDetail } from 'src/api/operations/get/survey/urls'

import { enqueueSnackbar } from 'notistack'
import { GetNotAvailableDocument } from 'src/api/operations/get/businessUpload'
import { Icon } from 'everchain-uilibrary'
import FillableDocumentForm from './FillableDocumentForm'
import NotAvailableDocument from '../NotAvailableDocument'

interface IFillableDocumentCard {
  fillableItem: any
  origin: string
  disableItems: boolean
  surveyId?: number | undefined
  surveyName?: string | undefined
}

const FillableDocumentCard: React.FC<IFillableDocumentCard> = ({
  fillableItem,
  origin,
  disableItems,
  surveyId,
  surveyName,
}: IFillableDocumentCard) => {
  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false)
  const context = useContext<any>(BusinessContext)
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [initialValues, setInitialValues] = useState<any>({})
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const queryClient = useQueryClient()

  const { isLoading: loadingSurveyDetail } = useQuery({
    queryKey: ['getSurveyDetail', surveyId],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: SurveyGetSurveyDetail,
        params: {
          id: surveyId,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setInitialValues(result.data)
      })
    },
    cacheTime: 0,
    enabled: surveyId !== undefined && surveyId > 0,
  })

  const closeFormDialog = () => {
    setOpenFormDialog(false)
  }
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const certificationPeriodSelected = useContext<ICertificationPeriod>(
    CertificationPeriodContext
  )
  const [fillableFormData, setFillableFormData] = useState<any | null>(null)
  const userToken = GetAccessToken()
  const [anchorAction, setAnchorAction] = React.useState<null | HTMLElement>(
    null
  )
  const [openNADocDialog, setOpenNADocDialog] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)

  const handleSeeDetails = () => {
    localStorage.removeItem('executiveSelected')
    setOpenFormDialog(true)
  }
  const isRegistration = origin === 'registration'
  const getStatusIcon = () => {
    if (fillableFormData && fillableFormData.length > 0) {
      if (fillableFormData.find((w: any) => w.status !== 'Accepted'))
        return (
          <Tooltip title="Pending">
            <Icon name="ErrorRounded" style={{ color: '#FFA500' }} />
          </Tooltip>
        )
      return (
        <Tooltip title="Accepted">
          <Icon name="CheckCircle" style={{ color: 'green' }} />
        </Tooltip>
      )
    }

    return (
      <Tooltip title="Pending">
        <Icon name="ErrorRounded" style={{ color: '#FFA500' }} />
      </Tooltip>
    )
  }

  const { isLoading: loadingDocs } = useQuery({
    queryKey: [
      'getFillableDocument',
      fillableItem.businessType,
      context.id,
      surveyId,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/fillabledocument`,
        params: {
          businessId: context.id,
          formType: fillableItem.businessType,
          certificationPeriodId: certificationPeriodSelected.id,
          surveyId,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setFillableFormData(result.data)
      })
    },
    enabled: isRegistration,
    cacheTime: 0,
  })

  const Download = () => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${BACKEND_URL}/fillableDocument/Download`,
      headers: {
        Authorization: userToken,
      },
      params: {
        id: Number(fillableFormData[0].id),
        name: fillableFormData[0].name,
      },
    })
      .then((result: any) => {
        setLoading(false)
        window.location.href = result.data
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { isLoading: loadingRepo } = useQuery({
    queryKey: [
      'getRepoFillableDocument',
      fillableItem.businessType,
      context.id,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/Repository/GetRepoFillable`,
        params: {
          formType: fillableItem.businessType,
          certificationPeriod: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setFillableFormData(result.data)
      })
    },
    enabled: !isRegistration,
    cacheTime: 0,
  })

  const acceptOrRejectSurvey = (value: any) => {
    if (value.status === 'Accepted') acceptSurvey.mutate(value)
    else rejectSurvey.mutate(value)
  }

  const rejectSurvey = useMutation({
    mutationFn: async (value: any) => {
      return axios({
        method: 'put',
        url: `${BACKEND_URL}/FillableDocument`,
        params: {
          fillableFormId: fillableFormData[0].id,
          status: value?.status,
          comment: value?.reason,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async (data) => {
      enqueueSnackbar('Survey rejected', notistackSucces)

      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })

      queryClient.refetchQueries({ queryKey: 'getFillableForm' })

      setOpenFormDialog(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    },
  })

  const acceptSurvey = useMutation({
    mutationFn: async (value: any) => {
      return axios({
        method: 'put',
        url: `${BACKEND_URL}/FillableDocument`,
        params: {
          fillableFormId: fillableFormData[0].id,
          status: value?.status,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async (data) => {
      enqueueSnackbar('Survey accepted', notistackSucces)

      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })

      queryClient.refetchQueries({ queryKey: 'getFillableForm' })

      setOpenFormDialog(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    },
  })

  const saveSurveyFillableDoc = useMutation({
    mutationFn: async () => {
      const formData = new FormData()

      const requestData = {
        businessId: context.id,
        businessName: context.name,
        businessTypeName: context.type,
        id: fillableItem !== null ? fillableItem.id : null,
        type: fillableItem.businessType,
        formName: fillableItem.name,
        fillableFormId: fillableItem.id,
        certificationPeriodId: certificationPeriodSelected.id,
        surveyId,
      }
      formData.append('requestData', JSON.stringify(requestData))

      formData.append('formDataValues', `[${JSON.stringify(initialValues)}]`)

      return axios({
        method: 'post',
        url: `${BACKEND_URL}/FillableDocument`,

        data: formData,
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Document filled.', notistackSucces)
      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      queryClient.refetchQueries({ queryKey: 'getFillableForm' })
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    },
  })

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAction(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorAction(null)
  }

  const getNotAvailableDocument = useQuery({
    queryKey: ['getNotAvailableDocument', context?.id, fillableItem?.id],
    queryFn: async () => {
      const result = await GetNotAvailableDocument(
        context.id,
        'fillableForm',
        fillableItem.id
      )
      return result
    },
  })
  const notAvailableDoc = getNotAvailableDocument?.data

  const fillableName = !surveyId
    ? fillableItem?.name
    : `${fillableItem?.name} (${surveyName})`

  return (
    <>
      {!loadingDocs && !loadingRepo && !loadingSurveyDetail && (
        <Grid item xs={12} md={6} lg={4}>
          <Card style={{ width: '100%', height: '270px' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="space-between"
              flexDirection="column"
              style={{ height: '100%' }}
            >
              <Box>
                <CardHeader
                  style={{ minHeight: '80px' }}
                  title={fillableName}
                  action={
                    isRegistration && (
                      <>
                        {fillableFormData &&
                          fillableFormData[0] != null &&
                          ['Accepted'].includes(fillableFormData[0].status) &&
                          fillableItem.businessType !== 'bank-account' &&
                          fillableItem.businessType !== 'media-settings' &&
                          fillableItem.businessType !== 'digital-survey' && (
                            <Tooltip title="Download">
                              <span>
                                <IconButton
                                  onClick={Download}
                                  color="primary"
                                  aria-label="Download"
                                  disabled={loading}
                                >
                                  <Icon name="GetApp" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        {fillableItem.isRequired && (
                          <Tooltip title="Required">
                            <IconButton aria-label="settings">
                              <Icon name="AssignmentLate" />
                            </IconButton>
                          </Tooltip>
                        )}

                        <IconButton aria-label="settings">
                          {getStatusIcon()}
                        </IconButton>

                        {isRegistration && notAvailableDoc?.active && (
                          <IconButton aria-label="settings">
                            <Tooltip title="Document not available">
                              <Icon
                                name="NotInterestedOutlined"
                                style={{ color: 'red' }}
                              />
                            </Tooltip>
                          </IconButton>
                        )}
                      </>
                    )
                  }
                />
                <Divider light style={{ height: '0.5px' }} />
              </Box>
              <Box>
                <CardContent style={{ width: '95%', minHeight: '150px' }}>
                  <Box>
                    {!fillableFormData || fillableFormData.length === 0 ? (
                      <Box mt={1}>
                        {certificationPeriodSelected.id === null
                          ? 'Information has not been provided. Please complete and submit the web form.'
                          : 'Information has not been provided.'}
                      </Box>
                    ) : (
                      <>
                        <Box mt={1}>
                          {`Submitted by: ${fillableFormData[0].submittedByUserName}`}
                        </Box>

                        <Box mt={2}>
                          {`Submitted on: ${formatDateAndTime(
                            fillableFormData[0].submittedOnUtc
                          )}`}
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Box>
              <Box display="flex" justifyContent="flex-end" flexDirection="row">
                <CardActions style={{ padding: '0px 16px 16px 16px' }}>
                  <Button
                    size="small"
                    disabled={loadingDocs || loadingRepo}
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                    onClick={handleActionClick}
                  >
                    Actions
                  </Button>

                  <Menu
                    id="action-menu"
                    anchorEl={anchorAction}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    open={Boolean(anchorAction)}
                    onClose={handleClose}
                  >
                    {isRegistration &&
                      certificationPeriodSelected.id === null && (
                        <MenuItem
                          onClick={() => {
                            handleClose()
                            setOpenNADocDialog(true)
                          }}
                          disabled={
                            notAvailableDoc !== null &&
                            notAvailableDoc &&
                            (notAvailableDoc.active === null ||
                              notAvailableDoc.active)
                          }
                        >
                          Set as not available
                        </MenuItem>
                      )}

                    <MenuItem
                      onClick={() => {
                        handleClose()
                        handleSeeDetails()
                      }}
                      disabled={!fillableFormData}
                    >
                      {certificationPeriodSelected.id !== null ||
                      disableItems ||
                      (fillableItem.businessType ===
                        'disclosure-authorization' &&
                        isInternal)
                        ? 'View Document'
                        : 'Fill Document'}
                    </MenuItem>
                  </Menu>
                </CardActions>
              </Box>
            </Box>
          </Card>
        </Grid>
      )}
      {openFormDialog && !surveyId && (
        <FillableDocumentForm
          form={fillableItem}
          open={openFormDialog}
          close={closeFormDialog}
          origin={origin}
        />
      )}

      {openNADocDialog && (
        <NotAvailableDocument
          documentId={fillableItem.id}
          documentName={fillableItem.name}
          close={() => setOpenNADocDialog(false)}
          open={openNADocDialog}
          type="fillableForm"
        />
      )}

      {openFormDialog && surveyId && (
        <SellerSurveyDialog
          openAnswerModal={openFormDialog}
          businessId={context.id}
          businessName={context.name}
          id={surveyId}
          initialValues={initialValues}
          approveOperation={isInternal}
          setOpenAnswerModal={setOpenFormDialog}
          origin={origin}
          saveSurveyOnFillableDocument={() => saveSurveyFillableDoc.mutate()}
          approveFillableDocument={acceptOrRejectSurvey}
          fillableDocStatus={
            fillableFormData ? fillableFormData[0]?.status : undefined
          }
        />
      )}
    </>
  )
}

FillableDocumentCard.defaultProps = {
  surveyId: undefined,
  surveyName: undefined,
}

export default FillableDocumentCard
