import React, { useContext, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  Box,
  CardContent,
  IconButton,
  Tooltip,
} from '@mui/material'
import axios from 'axios'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { BusinessContext } from 'src/context/BusinessContext'
import { BACKEND_URL } from 'src/api/axios/api'
import { useQuery } from 'react-query'
import { GetAccessToken } from 'src/api/axios/helper'
import { CheckCircle, ErrorRounded } from '@mui/icons-material'
import LicenseAttestationForm from './LicenseAttestationForm'

const LicenseAttestationCard: React.FC = () => {
  const [openFormDialog, setOpenFormDialog] = useState<boolean>(false)
  const { profileClient } = useContext(AuthContext)
  const [chosenJurisdictions, setChosenJurisdictions] = useState<any[]>([])
  const businessSelected = useContext<any>(BusinessContext)
  const userToken = GetAccessToken()
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const closeFormDialog = () => {
    setOpenFormDialog(false)
  }

  const handleSeeDetails = () => {
    setOpenFormDialog(true)
  }

  const licenseAttestationDataQuery = useQuery({
    queryKey: ['getLicenseAttestation', businessSelected.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/licenseattestation`,
        params: {
          businessId: businessSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      setChosenJurisdictions(data.map((x: any) => x.jurisdictionDisplay))
    },
    cacheTime: 0,
  })

  const licenseData = licenseAttestationDataQuery?.data

  const getStatusIcon = () => {
    if (licenseData && licenseData.length > 0) {
      return <CheckCircle style={{ color: 'green' }} />
    }
    return <ErrorRounded style={{ color: '#FFA500' }} />
  }

  if (licenseAttestationDataQuery.isLoading) {
    return <></>
  }

  return (
    <>
      <Grid item xs={12} md={6} lg={4}>
        <Card style={{ width: '100%', height: '270px' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="space-between"
            flexDirection="column"
            style={{ height: '100%' }}
          >
            <Box>
              <CardHeader
                style={{ minHeight: '80px' }}
                title={`License ${isUk ? '' : 'Attestation'}`}
                action={
                  <>
                    <Tooltip
                      title={
                        licenseData && chosenJurisdictions.length > 0 ? (
                          <>
                            <tr>
                              <td>Chosen Jurisdictions</td>
                            </tr>
                            <br />
                            <tr>
                              <td>
                                {`${licenseData
                                  .map((d: any) => d.name)
                                  .join(', ')}`}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ''
                        )
                      }
                    >
                      <IconButton aria-label="settings">
                        {getStatusIcon()}
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />
              <Divider light style={{ height: '0.5px' }} />
            </Box>
            <Box>
              <CardContent style={{ width: '95%', minHeight: '150px' }}>
                <Box>
                  <Box mt={2}>
                    {licenseData && chosenJurisdictions.length > 0
                      ? `${chosenJurisdictions.length} ${
                          chosenJurisdictions.length > 1
                            ? 'jurisdictions'
                            : 'jurisdiction'
                        } chosen`
                      : `Choose the jurisdictions in which your company is
                    registered.`}
                  </Box>
                </Box>
              </CardContent>
            </Box>

            <Box display="flex" justifyContent="flex-end" flexDirection="row">
              <CardActions style={{ padding: '0px 16px 16px 16px' }}>
                <Button
                  data-cy="fill-document"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSeeDetails}
                >
                  Choose Jurisdiction
                </Button>
              </CardActions>
            </Box>
          </Box>
        </Card>
      </Grid>
      {openFormDialog && (
        <LicenseAttestationForm open={openFormDialog} close={closeFormDialog} />
      )}
    </>
  )
}

export default LicenseAttestationCard
