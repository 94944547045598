import { Box, DialogContent, Typography } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import AuthService from 'src/configs/AuthService'
import { ConfirmDialog } from 'src/components/Dialogs'

interface IIdleModal {
  children: React.ReactNode
}
const IdleModal: React.FC<IIdleModal> = ({ children }) => {
  const auth = useMemo(() => new AuthService(), [])
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [seconds, setSeconds] = useState(
    Number(process.env.REACT_APP_NOTIFICATION_TIMEOUT_SECONDS)
  )

  useEffect(() => {
    const countdown = setInterval(() => {
      if (openContactDialog) {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          clearInterval(countdown)
          auth.logout()
        }
      } else {
        clearInterval(countdown)
        setSeconds(Number(process.env.REACT_APP_NOTIFICATION_TIMEOUT_SECONDS))
      }
    }, 1000)

    return () => {
      clearInterval(countdown)
    }
  }, [openContactDialog, seconds, auth])

  const handleOnIdle = (event: any) => {
    setOpenContactDialog(true)
  }

  useIdleTimer({
    timeout: 1000 * 60 * Number(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES),
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return (
    <>
      {children}
      <ConfirmDialog
        open={openContactDialog}
        title="Session Inactivity"
        closeName="Sign me out"
        confirmName="Stay Signed In"
        onClose={() => {
          auth.logout()
        }}
        onConfirm={() => {
          setOpenContactDialog(false)
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography component="div">
            Your session is about to end due to inactivity. Click{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              "Stay Signed In"
            </Box>{' '}
            to continue your session. You will be automatically signed out in{' '}
            <Box fontWeight="fontWeightMedium" display="inline">
              {seconds}
            </Box>{' '}
            seconds.
          </Typography>
        </DialogContent>
      </ConfirmDialog>
    </>
  )
}

export default IdleModal
