import React, { useEffect } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import AuthService from 'src/configs/AuthService'

const styles = { width: '100%' }

export const SignInCallback: React.FC = () => {
  useEffect(() => {
    const authService = new AuthService()
    authService.loginCallback()
  }, [])

  return (
    <div style={styles}>
      <LinearProgress />
    </div>
  )
}
