import { Button, Menu, MenuItem } from '@mui/material'
import axios from 'axios'
import { Icon } from 'everchain-uilibrary'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { notistackOptions } from 'src/configs/notistackOptions'

interface Props {
  files: any[]
  includeArchived: boolean
}

const DownloadSOP: React.FC<Props> = ({ files, includeArchived }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSuccess = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const [option, setOption] = useState('')

  useQuery({
    queryKey: ['downloadSOPFiles', files, option],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/downloadSOPFiles`,
        params: {
          option,
          files: JSON.stringify(files),
          includeArchived,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then((result: any) => {
          setOption('')
          return result.data
        })
        .catch((error: any) => {
          enqueueSnackbar('Error on downloading SOP.', notistackError)
          setOption('')
        })
    },
    onSuccess: (data) => {
      setOption('')
      window.location.href = data
    },
    enabled: option !== '',
  })

  const downloadFiles = (optionSelected: string) => {
    enqueueSnackbar(
      files.length === 1
        ? 'Downloading file.'
        : 'Creating zip file. This process may take some time.',
      notistackSuccess
    )
    setOption(optionSelected)
  }

  const [anchorElb, setAnchorElb] = useState(null)

  const openl = Boolean(anchorElb)

  const handleClickTemplate = (event: any) => {
    setAnchorElb(event.currentTarget)
  }

  const handleCloseTemplate = () => {
    setAnchorElb(null)
  }

  const handleOptionClick = (optionSelected: any) => {
    if (
      (optionSelected === 'selected-documents' && files.length > 0) ||
      optionSelected !== 'selected-documents'
    )
      downloadFiles(optionSelected)

    setAnchorElb(null)
  }

  return (
    <div>
      <Button
        id="template-button"
        aria-controls={openl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openl ? 'true' : undefined}
        onClick={handleClickTemplate}
        variant="contained"
        color="primary"
        endIcon={<Icon name="ArrowDropDown" />}
      >
        Download
      </Button>
      <Menu
        anchorEl={anchorElb}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id="simple-menu"
        open={openl}
        onClose={handleCloseTemplate}
        MenuListProps={{
          'aria-labelledby': 'template-button',
        }}
      >
        <MenuItem
          disabled={files.length === 0}
          onClick={() => handleOptionClick('selected-documents')}
        >
          Selected Documents
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick('all-documents')}>
          All Documents
        </MenuItem>
      </Menu>
    </div>
  )
}

export default DownloadSOP
