import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  Tooltip,
  Box,
} from '@mui/material'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { CategoryCommandCell } from 'src/components/GridCommandCell'
import { BACKEND_URL, CONTROLPANEL_URL } from 'src/api/axios/api'
import hardcodeData from 'src/utils/hardcodeData'
import { State } from '@progress/kendo-data-query'
import { GetAccessToken } from 'src/api/axios/helper'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'

import { isUkCountry } from 'src/utils/common'
import Loader from 'src/components/Loader'
import { useQuery } from 'react-query'
import { Icon } from 'everchain-uilibrary'
import CategoryOrder from './CategoryOrder'
import { DialogTitleStyled } from '../NewDocumentRegistration/style'

interface ICategory {
  name: string
  description: string
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  isEnabled: boolean
  buyerTypeName: string[]
  buyerPortfolioType: number[]
  buyerAssetType: number[]
  containsLicenseAttestation: boolean
  servicerType: string[]
}

const Category: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [data, setData] = useState<ICategory[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState(false)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openOrderDialog, setOpenOrderDialog] = useState(false)
  const userToken = GetAccessToken()
  const { userPermissions, profileClient } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const [total, setTotal] = useState(0)
  const [buyerPortfolioTypes, setBuyerPortfolioTypes] = useState<any[]>([])
  const [buyerPortfolioTypesSelected, setBuyerPortfolioTypesSelected] =
    useState<any[]>([])
  const [buyerAssetTypes, setBuyerAssetTypes] = useState<any[]>([])
  const [buyerAssetTypesSelected, setBuyerAssetTypesSelected] = useState<any[]>(
    []
  )

  const canManage =
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    )

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const getData = () => {
    axios({
      method: 'get',
      url: `${BACKEND_URL}/category`,
      params: { pagination: encodeURIComponent(JSON.stringify(gridState)) },
      headers: {
        Authorization: userToken,
      },
    }).then((result: any) => {
      setData(result.data.categories)
      setTotal(result.data.total)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState])

  const [initialValues] = useState<ICategory>({
    name: '',
    description: '',
    businessTypeName: [],
    validateBuyerTypeName: false,
    isEnabled: false,
    buyerTypeName: [],
    containsLicenseAttestation: false,
    buyerPortfolioType: [],
    buyerAssetType: [],
    servicerType: [],
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    isEnabled: Yup.bool().required('Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
    servicerType: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Servicer') ? schema.min(1, 'Required') : schema
    ),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const savedBuyerAssetTypes = buyerAssetTypes
        .filter((f) => values.buyerAssetType.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })
      const savedBuyerPortfolioTypes = buyerPortfolioTypes
        .filter((f) => values.buyerPortfolioType.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })

      const requestValues = {
        name: values.name || null,
        description: values.description || null,
        businessTypeName: values.businessTypeName || null,
        isEnabled: values.isEnabled,
        buyerTypeName: values.buyerTypeName || null,
        buyerAssetType: savedBuyerAssetTypes,
        buyerPortfolioType: savedBuyerPortfolioTypes,
        containsLicenseAttestation: values.containsLicenseAttestation,
        servicerType: values.servicerType || null,
      }

      setSaving(true)
      setLoading(true)
      axios({
        method: 'post',
        url: `${BACKEND_URL}/category`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('New data added', notistackSucces)
          setOpenAddDialog(false)
          axios({
            method: 'get',
            url: `${BACKEND_URL}/category`,
            params: { pagination: gridState },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            inputDataForm.resetForm({ values: initialValues })
            setData(result.data.categories)
            setTotal(result.data.total)
            setSaving(false)
            setLoading(false)
          })
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data, notistackError)
          setSaving(false)
          setLoading(false)
        })

      setSubmitting(false)
    },
  })

  const { isLoading: loadingBuyerPortfolioTypes } = useQuery({
    queryKey: ['getBuyerPortfolioTypes'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${CONTROLPANEL_URL}/client.GetPortfolioTypesRelatedAssetTypes`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setBuyerPortfolioTypes(
          result.data.map((x: any) => ({
            id: x.id,
            name: x.typeName,
            assetTypes: x.assetTypes,
          }))
        )
      }),
    cacheTime: 0,
  })

  const getAvailableBuyerPortfolioTypes = () => {
    const available = buyerPortfolioTypes
      ?.filter(
        (item) =>
          !inputDataForm.values.buyerPortfolioType?.some(
            (x: any) => x.id === item.id
          )
      )
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          displayName: `${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const getAvailableBuyerAssetTypes = () => {
    const available = buyerAssetTypes
      ?.filter(
        (item) =>
          !inputDataForm.values.buyerAssetType?.some(
            (x: any) => x.id === item.id
          )
      )
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          displayName: `${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderBuyerPortfolioType = () => {
    const handleChange = (value: any) => {
      setBuyerPortfolioTypesSelected(value)
      inputDataForm.setFieldValue('buyerPortfolioType', value)
      const buyerAssetTypesData = buyerPortfolioTypes
        .filter((x) => value.includes(x.id))
        .reduce((acc, obj) => {
          obj.assetTypes.forEach((asset: any) => {
            if (!acc.some((item: any) => item.id === asset.id)) {
              acc.push(asset)
            }
          })
          return acc
        }, [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      setBuyerAssetTypes(buyerAssetTypesData)
    }
    const buyerPortfolioTypesData = buyerPortfolioTypes
      ? getAvailableBuyerPortfolioTypes()
      : []

    return (
      <MultipleSelectionDropDown
        id="input-portfolioType"
        label="Buyer Portfolio Type"
        data={buyerPortfolioTypesData}
        disable={false}
        selectionState={buyerPortfolioTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const renderBuyerAssetType = () => {
    const handleChange = (value: any) => {
      setBuyerAssetTypesSelected(value)
      inputDataForm.setFieldValue('buyerAssetType', value)
    }

    const assetData = buyerAssetTypes ? getAvailableBuyerAssetTypes() : []

    return (
      <MultipleSelectionDropDown
        id="input-assetType"
        label="Buyer Asset Type"
        data={assetData}
        disable={false}
        selectionState={buyerAssetTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const CommandCell = (props: GridCellProps) => (
    <CategoryCommandCell
      dataItem={props.dataItem}
      buyerPortfolioTypes={buyerPortfolioTypes}
      buyerAssetTypes={buyerAssetTypes}
      confirmCallback={() => {
        setSaving(true)
        setLoading(true)
        axios({
          method: 'delete',
          url: `${BACKEND_URL}/category/${props.dataItem.id}`,
          headers: {
            Authorization: userToken,
          },
        })
          .then(() => {
            enqueueSnackbar('Data deleted', notistackSucces)
            axios({
              method: 'get',
              url: `${BACKEND_URL}/category`,
              params: { pagination: gridState },
              headers: {
                Authorization: userToken,
              },
            }).then((result: any) => {
              setData(result.data.categories)
              setTotal(result.data.total)
              setSaving(false)
              setLoading(false)
            })
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data, notistackError)
            setSaving(false)
            setLoading(false)
          })
      }}
      updateSuccededCallback={() => {
        setSaving(true)
        setLoading(true)
        axios({
          method: 'get',
          url: `${BACKEND_URL}/category`,
          params: { pagination: gridState },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          setData(result.data.categories)
          setTotal(result.data.total)
          setSaving(false)
          setLoading(false)
        })
      }}
    />
  )

  const handleClose = () => {
    setOpenOrderDialog(false)
  }

  return (
    <>
      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container>
          <Can
            do={
              PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup
            }
            on="any"
          >
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  data-cy="create-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenOrderDialog(true)
                  }}
                  disabled={!data || data.length === 0}
                >
                  Order Category
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-cy="create-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setBuyerPortfolioTypesSelected([])
                    setBuyerAssetTypesSelected([])
                    setOpenAddDialog(true)
                  }}
                  disabled={!canManage}
                >
                  Create New Category
                </Button>
              </Grid>
            </Grid>
          </Can>
          <Grid container style={{ marginTop: '24px' }}>
            <Grid item>
              {(loading || loadingBuyerPortfolioTypes) && loadingPanel}
              <KendoGrid
                data={data || []}
                sortable
                style={{
                  height: 'auto',
                  maxHeight: window.innerHeight * 0.6,
                }}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
              >
                <KendoGridColumn
                  field="id"
                  title="ID"
                  width="80px"
                  cell={(props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {props.dataItem[field]}
                      </td>
                    )
                  }}
                />
                <KendoGridColumn field="name" title="Name" />
                <KendoGridColumn field="description" title="Description" />
                <KendoGridColumn
                  field="isEnabled"
                  title="Is Enabled?"
                  cell={(props: GridCellProps) => {
                    const field = props.field || ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                {!isUk && (
                  <KendoGridColumn
                    field="containsLicenseAttestation"
                    title="Contains License Attestation?"
                    cell={(props: GridCellProps) => {
                      const field = props.field || ''
                      return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                    }}
                  />
                )}
                <KendoGridColumn
                  field="businessTypeName"
                  title="Business Types"
                  sortable={false}
                />
                <KendoGridColumn
                  field="buyerTypeName"
                  title="Buyer Type"
                  sortable={false}
                />
                <KendoGridColumn
                  field="enabledDocuments"
                  title="Enabled Documents"
                  sortable={false}
                  cell={(props: GridCellProps) => {
                    const field = props.field || ''
                    return (
                      <td style={{ textAlign: 'right' }}>
                        {props.dataItem[field]}
                      </td>
                    )
                  }}
                />
                <KendoGridColumn
                  field="buyerPortfolioType"
                  title="Buyer Portfolio Type"
                  sortable={false}
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    const buyerPortfolioTypeDisplay = buyerPortfolioTypes
                      .filter((x) => props.dataItem[field].includes(x.id))
                      .map((x) => `${x.name}`)
                      .join(', ')

                    if (buyerPortfolioTypeDisplay.length > 15)
                      return (
                        <Tooltip title={buyerPortfolioTypeDisplay}>
                          <td>
                            <MoreHorizIcon />
                          </td>
                        </Tooltip>
                      )

                    return <td>{buyerPortfolioTypeDisplay}</td>
                  }}
                />
                <KendoGridColumn
                  field="buyerAssetType"
                  title="Buyer Asset Type"
                  sortable={false}
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''

                    const buyerAssetTypesData = buyerPortfolioTypes
                      .filter((x) =>
                        props.dataItem['buyerPortfolioType'].includes(x.id)
                      )
                      .reduce((acc, obj) => {
                        obj.assetTypes.forEach((asset: any) => {
                          if (!acc.some((item: any) => item.id === asset.id)) {
                            acc.push(asset)
                          }
                        })
                        return acc
                      }, [])
                      .sort((a: any, b: any) => a.name.localeCompare(b.name))
                    const buyerAssetTypeDisplay = buyerAssetTypesData
                      .filter((x: any) => props.dataItem[field].includes(x.id))
                      .map((x: any) => `${x.name}`)
                      .join(', ')

                    if (buyerAssetTypeDisplay.length > 15)
                      return (
                        <Tooltip title={buyerAssetTypeDisplay}>
                          <td>
                            <MoreHorizIcon />
                          </td>
                        </Tooltip>
                      )

                    return <td>{buyerAssetTypeDisplay}</td>
                  }}
                />
                {canManage && <KendoGridColumn cell={CommandCell} />}
              </KendoGrid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={openAddDialog}
          onClose={() => {
            inputDataForm.resetForm({ values: initialValues })
            setOpenAddDialog(false)
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Category
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                inputDataForm.resetForm({ values: initialValues })
                setOpenAddDialog(false)
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <form onSubmit={inputDataForm.handleSubmit}>
              {saving && <Loader specificHeight={150} />}
              <Grid
                container
                style={{
                  minWidth: '400px',
                  maxWidth: '600px',
                }}
                spacing={3}
              >
                <Grid item direction="column" xs={12} md={6} lg={6} spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-name"
                      label="Name"
                      name="name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.name}
                      error={!!inputDataForm.errors.name}
                      helperText={inputDataForm.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} style={{ paddingTop: '3px' }}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-description"
                      label="Description"
                      name="description"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 250,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.description}
                      error={!!inputDataForm.errors.description}
                      helperText={inputDataForm.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} spacing={3} direction="column">
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography component="div">
                      <Grid
                        data-cy="dialog-disable-enabled-selector"
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Disabled</Grid>
                        <Grid item>
                          <Switch
                            checked={inputDataForm.values.isEnabled}
                            onChange={inputDataForm.handleChange}
                            name="isEnabled"
                          />
                        </Grid>
                        <Grid item>Enabled</Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    style={{ paddingTop: '5px' }}
                  >
                    {!isUk && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              inputDataForm.values.containsLicenseAttestation
                            }
                            onChange={inputDataForm.handleChange}
                            name="containsLicenseAttestation"
                          />
                        }
                        label="Contains License Attestation"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormGroup>
                    <FormLabel
                      data-cy="dialog-business-types-label"
                      component="legend"
                    >
                      Business Types
                    </FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.businessTypeName}
                    </FormHelperText>
                    <Box display="flex" flexDirection="row">
                      {hardcodeData.getBusinessType().map((x) => {
                        return (
                          <FormControlLabel
                            data-cy={`dialog-checkbox-${x.id}`}
                            key={`checkbox_${x.id}`}
                            label={x.name}
                            control={
                              <Checkbox
                                name="businessTypeName"
                                onChange={(e) => {
                                  if (e.target.value === 'Buyer')
                                    inputDataForm.setFieldValue(
                                      'validateBuyerTypeName',
                                      !inputDataForm.values
                                        .validateBuyerTypeName
                                    )
                                  inputDataForm.handleChange(e)
                                }}
                                checked={inputDataForm.values.businessTypeName.includes(
                                  x.name
                                )}
                                value={x.name}
                              />
                            }
                          />
                        )
                      })}
                    </Box>
                  </FormGroup>
                </Grid>
                {inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Buyer'
                ) && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup data-cy="dialog-buyer-type-label">
                      <FormLabel component="legend">Buyer Type</FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.buyerTypeName}
                      </FormHelperText>
                      <Box display="flex" flexDirection="row">
                        <FormControlLabel
                          label="Active"
                          control={
                            <Checkbox
                              data-cy="dialog-buyer-type-active-checkbox"
                              name="buyerTypeName"
                              onChange={inputDataForm.handleChange}
                              value="Active"
                              checked={inputDataForm.values.buyerTypeName.includes(
                                'Active'
                              )}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Passive"
                          control={
                            <Checkbox
                              data-cy="dialog-buyer-type-passive-checkbox"
                              name="buyerTypeName"
                              onChange={inputDataForm.handleChange}
                              value="Passive"
                              checked={inputDataForm.values.buyerTypeName.includes(
                                'Passive'
                              )}
                            />
                          }
                        />
                      </Box>
                      {inputDataForm.values.businessTypeName.find(
                        (x) => x === 'Buyer'
                      ) && (
                        <Box paddingBottom="5px" paddingTop="5px">
                          <Grid item>{renderBuyerPortfolioType()}</Grid>
                          <Grid item>{renderBuyerAssetType()}</Grid>
                        </Box>
                      )}
                    </FormGroup>
                  </Grid>
                )}
                {inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Servicer'
                ) && (
                  <Grid item xs={12} md={6} lg={6}>
                    <FormGroup data-cy="dialog-Servicer-type-label">
                      <FormLabel component="legend">Servicer Type</FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.servicerType}
                      </FormHelperText>
                      <Box display="flex" flexDirection="row">
                        <FormControlLabel
                          label="Internal"
                          control={
                            <Checkbox
                              data-cy="dialog-servicer-type-internal-checkbox"
                              name="servicerType"
                              onChange={inputDataForm.handleChange}
                              value="Internal"
                              checked={inputDataForm.values.servicerType.includes(
                                'Internal'
                              )}
                            />
                          }
                        />
                        <FormControlLabel
                          label="External"
                          control={
                            <Checkbox
                              data-cy="dialog-servicer-type-external-checkbox"
                              name="servicerType"
                              onChange={inputDataForm.handleChange}
                              value="External"
                              checked={inputDataForm.values.servicerType.includes(
                                'External'
                              )}
                            />
                          }
                        />
                      </Box>
                    </FormGroup>
                  </Grid>
                )}

                <Grid
                  container
                  justifyContent="flex-end"
                  spacing={1}
                  style={{ paddingBottom: '5px', paddingTop: '5px' }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setOpenAddDialog(false)
                        inputDataForm.resetForm({ values: initialValues })
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="dialog-create-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        !inputDataForm.dirty || !inputDataForm.isValid || saving
                      }
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openOrderDialog}
          onClose={() => setOpenOrderDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Order Category
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenOrderDialog(false)}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <CategoryOrder close={handleClose} />
          </DialogContent>
        </Dialog>
      </form>
    </>
  )
}

export default Category
