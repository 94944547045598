import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import axios from 'axios'
import * as React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { BACKEND_URL } from 'src/api/axios/api'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import hardcodeData from 'src/utils/hardcodeData'
import { GetAccessToken } from 'src/api/axios/helper'
import { isUkCountry } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from './style'
import { ConfirmDialog } from '../Dialogs'
import Loader from '../Loader'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'

interface CategoryCommandCellProps {
  dataItem: any
  confirmCallback: () => void
  updateSuccededCallback: () => void
  buyerPortfolioTypes: any[]
  buyerAssetTypes: any[]
}

interface UpdateCategoryForm {
  id: number
  name: string
  description: string
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  isEnabled: boolean
  buyerTypeName: string[]
  buyerPortfolioType: []
  buyerAssetType: []
  containsLicenseAttestation: boolean
  servicerType: string[]
}

const CategoryCommandCell: React.FC<CategoryCommandCellProps> = ({
  dataItem,
  confirmCallback,
  updateSuccededCallback,
  buyerPortfolioTypes,
  buyerAssetTypes,
}: CategoryCommandCellProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [loading, setLoading] = React.useState(false)
  const userToken = GetAccessToken()

  const [documentAffectedMsg, setDocumentAffectedMsg] = React.useState('')
  const [openDocumentAffected, setOpenDocumentAffected] = React.useState(false)

  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const [categoryInfo, setCategoryInfo] = React.useState<any>()
  const [buyerPortfolioTypesSelected, setBuyerPortfolioTypesSelected] =
    React.useState<any[]>([])
  const [buyerAssetTypesAvailable, setBuyerAssetTypesAvailable] =
    React.useState<any[]>([])
  const [buyerAssetTypesSelected, setBuyerAssetTypesSelected] = React.useState<
    any[]
  >([])

  const [initialValues] = React.useState<UpdateCategoryForm>({
    id: dataItem.id,
    name: dataItem.name,
    description: dataItem.description,
    businessTypeName: dataItem.businessTypeName,
    validateBuyerTypeName: dataItem.businessTypeName.includes('Buyer'),
    isEnabled: dataItem.isEnabled,
    buyerTypeName: dataItem.buyerTypeName,
    containsLicenseAttestation: dataItem.containsLicenseAttestation,
    buyerPortfolioType: [],
    buyerAssetType: [],
    servicerType: dataItem.servicerType,
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    isEnabled: Yup.bool().required('Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
    servicerType: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Servicer') ? schema.min(1, 'Required') : schema
    ),
  })

  useQuery({
    enabled: openEditDialog,
    queryKey: ['GetCategoryById'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/category/GetById`,
        params: {
          id: dataItem.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCategoryInfo(result.data)
      }),
    cacheTime: 0,
  })

  const validateBusinessTypes = (values: any) => {
    const currentCategory = values

    let businessTypeExcept: any[] = []
    let buyerTypeExcept: any[] = []
    let servicerTypeExcept: any[] = []

    const documentsAffected: any[] = []

    categoryInfo?.documents.forEach((doc: any) => {
      businessTypeExcept =
        doc.businessTypeName?.filter(
          (item: any) => !currentCategory?.businessTypeName.includes(item)
        ) || []

      buyerTypeExcept =
        doc.buyerTypeName?.filter(
          (item: any) => !currentCategory?.buyerTypeName.includes(item)
        ) || []

      servicerTypeExcept =
        doc.servicerType?.filter(
          (item: any) => !currentCategory?.servicerType.includes(item)
        ) || []

      if (
        businessTypeExcept.length > 0 ||
        buyerTypeExcept.length > 0 ||
        servicerTypeExcept.length > 0
      ) {
        documentsAffected.push(doc.name)
      }
    })

    if (documentsAffected.length > 0) {
      return `${documentsAffected.join(', ')}`
    }

    return ''
  }

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const savedBuyerAssetTypes = buyerAssetTypesAvailable
        .filter((f) => buyerAssetTypesSelected.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })
      const savedBuyerPortfolioTypes = buyerPortfolioTypes
        .filter((f) => buyerPortfolioTypesSelected.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })
      const requestValues = {
        id: values.id,
        name: values.name || null,
        description: values.description || null,
        businessTypeName: values.businessTypeName || null,
        isEnabled: values.isEnabled,
        buyerTypeName: values.buyerTypeName || null,
        buyerAssetType: savedBuyerAssetTypes,
        buyerPortfolioType: savedBuyerPortfolioTypes,
        containsLicenseAttestation: values.containsLicenseAttestation,
        servicerType: values.servicerType || null,
      }
      setLoading(true)
      axios({
        method: 'put',
        url: `${BACKEND_URL}/category`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Data updated', notistackSucces)
          setLoading(false)
          updateSuccededCallback()
        })
        .catch((error) => {
          setLoading(false)
          enqueueSnackbar(error.response.data, notistackError)
        })

      setSubmitting(false)
    },
  })

  const getAvailableBuyerPortfolioTypes = () => {
    const available = buyerPortfolioTypes.map((x) => {
      return {
        id: x.id,
        name: x.name,
        displayName: `${x.name}`,
      }
    })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const getAvailableBuyerAssetTypes = () => {
    const available = buyerAssetTypesAvailable.map((x) => {
      return {
        id: x.id,
        name: x.name,
        displayName: `${x.name}`,
      }
    })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const handleSubmit = () => {
    const validateBusiness = validateBusinessTypes(initialValues)

    if (validateBusiness !== '') {
      setDocumentAffectedMsg(validateBusiness)
      setOpenDocumentAffected(true)
    } else {
      inputDataForm.submitForm()
    }
  }

  const renderBuyerPortfolioType = () => {
    const handleChange = (value: any) => {
      setBuyerPortfolioTypesSelected(value)
      inputDataForm.setFieldValue('buyerPortfolioType', value)
      const buyerAssetTypesData = buyerPortfolioTypes
        .filter((x) => value.includes(x.id))
        .reduce((acc, obj) => {
          obj.assetTypes.forEach((asset: any) => {
            if (!acc.some((item: any) => item.id === asset.id)) {
              acc.push(asset)
            }
          })
          return acc
        }, [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
      setBuyerAssetTypesAvailable(buyerAssetTypesData)
    }
    const buyerPortfolioTypesData = buyerPortfolioTypes
      ? getAvailableBuyerPortfolioTypes()
      : []

    return (
      <MultipleSelectionDropDown
        id="input-portfolioType"
        label="Buyer Portfolio Type"
        data={buyerPortfolioTypesData}
        disable={false}
        selectionState={buyerPortfolioTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const renderBuyerAssetType = () => {
    const handleChange = (value: any) => {
      setBuyerAssetTypesSelected(value)
      inputDataForm.setFieldValue('buyerAssetType', value)
    }

    const assetData = buyerAssetTypesAvailable
      ? getAvailableBuyerAssetTypes()
      : []

    return (
      <MultipleSelectionDropDown
        id="input-assetType"
        label="Buyer Asset Type"
        data={assetData}
        disable={false}
        selectionState={buyerAssetTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const SetDefaultValuesToBuyerPortfolioTypes = () => {
    const savedBuyerPortfolioTypes = buyerPortfolioTypes
      .filter((f) => dataItem.buyerPortfolioType.includes(f.id))
      ?.map((c) => {
        return c.id
      })
    setBuyerPortfolioTypesSelected(savedBuyerPortfolioTypes)

    const buyerAssetTypesData = buyerPortfolioTypes
      .filter((x) => savedBuyerPortfolioTypes.includes(x.id))
      .reduce((acc, obj) => {
        obj.assetTypes.forEach((asset: any) => {
          if (!acc.some((item: any) => item.id === asset.id)) {
            acc.push(asset)
          }
        })
        return acc
      }, [])
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
    setBuyerAssetTypesAvailable(buyerAssetTypesData)
    const savedBuyerAssetTypes = buyerAssetTypesData
      .filter((f: any) => dataItem.buyerAssetType.includes(f.id))
      ?.map((c: any) => {
        return c.id
      })
    setBuyerAssetTypesSelected(savedBuyerAssetTypes)
  }

  React.useEffect(() => {
    if (dataItem && dataItem.buyerPortfolioType) {
      SetDefaultValuesToBuyerPortfolioTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerPortfolioTypes, dataItem])

  return (
    <>
      <td className="k-command-cell">
        <IconButton
          data-cy="grid-delete-button"
          onClick={() => {
            setOpenDeleteDialog(true)
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          data-cy="grid-edit-button"
          onClick={() => {
            setOpenEditDialog(true)
          }}
        >
          <Edit />
        </IconButton>
      </td>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Category
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <form onSubmit={inputDataForm.handleSubmit}>
            {loading && <Loader specificHeight={150} />}
            <Grid
              container
              style={{
                minWidth: '400px',
                maxWidth: '600px',
              }}
              spacing={3}
            >
              <Grid item direction="column" xs={12} md={6} lg={6} spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-name"
                    label="Name"
                    name="name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    onChange={inputDataForm.handleChange}
                    value={inputDataForm.values.name}
                    error={!!inputDataForm.errors.name}
                    helperText={inputDataForm.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingTop: '3px' }}>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-description"
                    label="Description"
                    name="description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 250,
                    }}
                    onChange={inputDataForm.handleChange}
                    value={inputDataForm.values.description}
                    error={!!inputDataForm.errors.description}
                    helperText={inputDataForm.errors.description}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} spacing={3} direction="column">
                <Grid item xs={12} md={12} lg={12}>
                  <Typography component="div">
                    <Grid
                      data-cy="dialog-disable-enabled-selector"
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Disabled</Grid>
                      <Grid item>
                        <Switch
                          checked={inputDataForm.values.isEnabled}
                          onChange={inputDataForm.handleChange}
                          name="isEnabled"
                        />
                      </Grid>
                      <Grid item>Enabled</Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  style={{ paddingTop: '5px' }}
                >
                  {!isUk && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            inputDataForm.values.containsLicenseAttestation
                          }
                          onChange={inputDataForm.handleChange}
                          name="containsLicenseAttestation"
                        />
                      }
                      label="Contains License Attestation"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <FormGroup>
                  <FormLabel
                    data-cy="dialog-business-types-label"
                    component="legend"
                  >
                    Business Types
                  </FormLabel>
                  <FormHelperText style={{ color: 'red' }}>
                    {inputDataForm.errors.businessTypeName}
                  </FormHelperText>
                  <Box display="flex" flexDirection="row">
                    {hardcodeData.getBusinessType().map((x) => {
                      return (
                        <FormControlLabel
                          data-cy={`dialog-checkbox-${x.id}`}
                          key={`checkbox_${x.id}`}
                          label={x.name}
                          control={
                            <Checkbox
                              name="businessTypeName"
                              onChange={(e) => {
                                if (e.target.value === 'Buyer')
                                  inputDataForm.setFieldValue(
                                    'validateBuyerTypeName',
                                    !inputDataForm.values.validateBuyerTypeName
                                  )
                                inputDataForm.handleChange(e)
                              }}
                              checked={inputDataForm.values.businessTypeName.includes(
                                x.name
                              )}
                              value={x.name}
                            />
                          }
                        />
                      )
                    })}
                  </Box>
                </FormGroup>
              </Grid>
              {inputDataForm.values.businessTypeName.find(
                (x) => x === 'Buyer'
              ) && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormGroup data-cy="dialog-buyer-type-label">
                    <FormLabel component="legend">Buyer Type</FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.buyerTypeName}
                    </FormHelperText>
                    <Box display="flex" flexDirection="row">
                      <FormControlLabel
                        label="Active"
                        control={
                          <Checkbox
                            data-cy="dialog-buyer-type-active-checkbox"
                            name="buyerTypeName"
                            onChange={inputDataForm.handleChange}
                            value="Active"
                            checked={inputDataForm.values.buyerTypeName.includes(
                              'Active'
                            )}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Passive"
                        control={
                          <Checkbox
                            data-cy="dialog-buyer-type-passive-checkbox"
                            name="buyerTypeName"
                            onChange={inputDataForm.handleChange}
                            value="Passive"
                            checked={inputDataForm.values.buyerTypeName.includes(
                              'Passive'
                            )}
                          />
                        }
                      />
                    </Box>
                    {inputDataForm.values.businessTypeName.find(
                      (x) => x === 'Buyer'
                    ) && (
                      <Box paddingBottom="5px">
                        <Grid item>{renderBuyerPortfolioType()}</Grid>
                        <Grid item>{renderBuyerAssetType()}</Grid>
                      </Box>
                    )}
                  </FormGroup>
                </Grid>
              )}
              {inputDataForm.values.businessTypeName.find(
                (x) => x === 'Servicer'
              ) && (
                <Grid item xs={12} md={6} lg={6}>
                  <FormGroup data-cy="dialog-Servicer-type-label">
                    <FormLabel component="legend">Servicer Type</FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.servicerType}
                    </FormHelperText>
                    <Box display="flex" flexDirection="row">
                      <FormControlLabel
                        label="Internal"
                        control={
                          <Checkbox
                            data-cy="dialog-servicer-type-internal-checkbox"
                            name="servicerType"
                            onChange={inputDataForm.handleChange}
                            value="Internal"
                            checked={inputDataForm.values.servicerType.includes(
                              'Internal'
                            )}
                          />
                        }
                      />
                      <FormControlLabel
                        label="External"
                        control={
                          <Checkbox
                            data-cy="dialog-servicer-type-external-checkbox"
                            name="servicerType"
                            onChange={inputDataForm.handleChange}
                            value="External"
                            checked={inputDataForm.values.servicerType.includes(
                              'External'
                            )}
                          />
                        }
                      />
                    </Box>
                  </FormGroup>
                </Grid>
              )}
              <Grid
                container
                justifyContent="flex-end"
                spacing={1}
                style={{ paddingBottom: '5px', paddingTop: '5px' }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenEditDialog(false)
                      inputDataForm.resetForm({ values: initialValues })
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-cy="update-button"
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={
                      !inputDataForm.dirty || !inputDataForm.isValid || loading
                    }
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      {openDocumentAffected && (
        <ConfirmDialog
          open={openDocumentAffected}
          title="Category"
          description={
            <>
              <Box>
                The business types from following documents, are different from
                this category. Would you like to proceed?
              </Box>
              <Box mt={5}>{`${documentAffectedMsg}`}</Box>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDocumentAffected(false)}
          onConfirm={() => {
            inputDataForm.submitForm()
            setOpenDocumentAffected(false)
          }}
        />
      )}

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          title="Delete"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Would you like to delete this category?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            confirmCallback()
            setOpenDeleteDialog(false)
          }}
        />
      )}
    </>
  )
}

export default CategoryCommandCell
