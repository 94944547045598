import React, { useContext, useState } from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete'

import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { maskPhoneNumber } from 'src/utils/masker'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { ConfirmDialog } from '../Dialogs'

interface IExecutives {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  selectItem: (id: string) => void
  fillableForms: any
  selectedItem: any
  readOnly: boolean
  deleteFormItem: (id: string) => void
  origin: string
}

const Executives: React.FC<IExecutives> = ({
  handleChange,
  inputDataForm,
  selectItem,
  fillableForms,
  selectedItem,
  readOnly,
  deleteFormItem,
  origin,
}: IExecutives) => {
  const { userPermissions, profileClient } = useContext(AuthContext)

  const [executiveToRemove, setExecutiveToRemove] = useState('')

  const [openDeleteExecutiveDialog, setOpenDeleteExecutiveDialog] =
    useState<boolean>(false)

  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const DeleteItemForm = (id: string) => {
    setOpenDeleteExecutiveDialog(false)
    deleteFormItem(id)
  }

  return (
    <>
      {!fillableForms ? (
        <Box display="flex" flexDirection="row" width="60em" />
      ) : (
        <Box display="flex" flexDirection="row" width="60em">
          <List
            style={{ minWidth: '220px' }}
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Executives Submitted
              </ListSubheader>
            }
          >
            {fillableForms &&
              fillableForms.map((x: any) => (
                <ListItem
                  key={x.id}
                  selected={
                    x.id ===
                    (selectedItem && selectedItem?.id !== null
                      ? selectedItem?.id
                      : null)
                  }
                >
                  <Box
                    style={{ width: '100%' }}
                    borderColor="blue"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                      >
                        <Tooltip title={x.status}>
                          {x.status === 'Accepted' ? (
                            <Icon name="Check" fontSize="small" color="green" />
                          ) : x.status === 'Pending Upload' ? (
                            <Icon name="Close" fontSize="small" color="red" />
                          ) : (
                            <Icon
                              name="ErrorOutline"
                              fontSize="small"
                              color="gold"
                            />
                          )}
                        </Tooltip>
                      </Box>
                      <Box
                        style={{ cursor: 'pointer' }}
                        ml={2}
                        onClick={() => {
                          selectItem(x.id)
                          localStorage.setItem('executiveSelected', x.id)
                        }}
                      >
                        {`${JSON.parse(x.formData)[0].firstName} ${
                          JSON.parse(x.formData)[0].lastName
                        }`}{' '}
                      </Box>
                    </Box>
                    {x.status === 'Pending Acceptance' && (
                      <Box ml={2}>
                        <Tooltip title="Remove executive.">
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={() => {
                              setExecutiveToRemove(x.id)
                              setOpenDeleteExecutiveDialog(true)
                            }}
                          >
                            <DeleteIcon color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </ListItem>
              ))}
          </List>

          <Grid
            container
            direction="row"
            style={{ margin: '6px 6px 6px 6px', width: '800px' }}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-firstName"
                label="First Name"
                name="firstName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.firstName || ''}
                error={!!inputDataForm.errors.firstName}
                helperText={inputDataForm.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-lastname"
                label="Last Name"
                name="lastName"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.lastName || ''}
                error={!!inputDataForm.errors.lastName}
                helperText={inputDataForm.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-jobTitle"
                label="Job Title"
                name="jobTitle"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.jobTitle || ''}
                error={!!inputDataForm.errors.jobTitle}
                helperText={inputDataForm.errors.jobTitle}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-last4Ssn"
                label="Last 4 SSN"
                name="last4Ssn"
                inputProps={{ maxLength: 4 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  const newValue = value.replace(/[^0-9.]/g, '')
                  handleChange(name, newValue.substring(0, 4), true)
                }}
                onBlur={({ target: { value, name } }) => {
                  if (inputDataForm.isDirty) {
                    const newValue = value.replace(/[^0-9.]/g, '')
                    handleChange(name, newValue.substring(0, 4), true)
                  }
                }}
                value={
                  !isInternal && origin === 'thirdPartyRepo'
                    ? ''
                    : inputDataForm.values.last4Ssn || ''
                }
                error={!!inputDataForm.errors.last4Ssn}
                helperText={inputDataForm.errors.last4Ssn}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                disabled={readOnly}
                style={{ width: '100%' }}
                data-cy="dialog-input-field-dateofbirth"
                id="dateOfBirth"
                label="Date of Birth"
                name="dateOBirth"
                maxDate={new Date()}
                value={
                  !isInternal && origin === 'thirdPartyRepo'
                    ? ''
                    : inputDataForm.values.dateOfBirth || null
                }
                country={country}
                errorMessage={inputDataForm.errors.dateOfBirth?.toString()}
                onChange={(date: any) => {
                  handleChange('dateOfBirth', date, true)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-phone"
                label="Phone"
                name="phone"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, country)
                  handleChange(name, masked, true)
                }}
                value={inputDataForm.values.phone || ''}
                error={!!inputDataForm.errors.phone}
                helperText={inputDataForm.errors.phone}
                placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={readOnly}
                fullWidth
                data-cy="dialog-input-field-emailAddress"
                label="Email Address"
                name="emailAddress"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, true)
                }}
                value={inputDataForm.values.emailAddress || ''}
                error={!!inputDataForm.errors.emailAddress}
                helperText={inputDataForm.errors.emailAddress}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={readOnly}
                fullWidth
                type="number"
                data-cy="dialog-input-field-id"
                label="id"
                name="id"
                hidden={true}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                value={inputDataForm.values.id}
                error={!!inputDataForm.errors.id}
                helperText={inputDataForm.errors.id}
              />
            </Grid>
            {isInternal && (
              <Box>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography>
                    <b> Background Check Authorization</b>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-cy="background-check-checkbox"
                        checked={inputDataForm.values.backgroundCheck ?? false}
                        onChange={({ target: { value, name } }) => {
                          inputDataForm.setFieldValue(
                            name,
                            !inputDataForm.values.backgroundCheck,
                            false
                          )
                          handleChange(
                            name,
                            !inputDataForm.values.backgroundCheck,
                            false
                          )
                        }}
                        name="backgroundCheck"
                        color="primary"
                      />
                    }
                    label="Background Check Completed"
                  />
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      )}

      {openDeleteExecutiveDialog && (
        <ConfirmDialog
          open={openDeleteExecutiveDialog}
          title="Remove Executive"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to delete this executive?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteExecutiveDialog(false)}
          onConfirm={() => DeleteItemForm(executiveToRemove)}
        />
      )}
    </>
  )
}

export default Executives
