import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  BACKEND_URL,
  CONTROLPANEL_URL,
  GetAccessToken,
} from 'src/api/axios/api'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { SELLER } from 'src/configs/AuthService'
import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from '../Dialogs/style'
import Loader from '../Loader'

interface CloneAllDocumentsProps {
  businessId: string
  open: boolean
  close: () => void
}

interface IValues {
  businessId: string
  clientId: number | null
}

const CloneAllDocuments: React.FC<CloneAllDocumentsProps> = ({
  businessId,
  open,
  close,
}) => {
  const onCloseDialog = () => close()
  const { enqueueSnackbar } = useSnackbar()
  const notistackSuccess = notistackOptions('success')
  const [loading, setLoading] = useState(true)
  const notistackError = notistackOptions('error')
  const [initialValues] = useState<IValues>({
    businessId: '',
    clientId: null,
  })

  const inputDataFormSchema = Yup.object().shape({
    businessId: Yup.string().nullable().required('Required'),
    client: Yup.number().nullable().required('Required'),
  })

  const onlyUnique = (value: any, index: number, array: any[]) => {
    return array.findIndex((v) => v.clientId === value.clientId) === index
  }

  const getClients = (values: any[]) => {
    const clients = values.map(({ clientId, clientName }) => ({
      clientId,
      clientName,
    }))
    const sortedClients = [...clients].sort((a, b) =>
      a.clientName.localeCompare(b.clientName)
    )
    return sortedClients.filter(onlyUnique)
  }

  useQuery({
    queryKey: ['GetRelatedBusinesses', businessId],
    queryFn: async () => {
      axios({
        method: 'get',
        url: `${CONTROLPANEL_URL}/business.getRelatedBusinesses`,
        params: {
          businessId,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setLoading(false)
        setRelatedBusiness(result.data)
        setClients(getClients(result.data))
      })
    },
    cacheTime: 0,
  })

  const [clients, setClients] = useState<any[]>()
  const [relatedBusiness, setRelatedBusiness] = useState<any[]>()
  const userToken = GetAccessToken()

  const handleSubmit = () => {
    cloneAllDocuments.mutate()
  }

  const cloneAllDocuments = useMutation({
    mutationFn: async () => {
      const { values } = inputDataForm
      axios({
        method: 'post',
        url: `${BACKEND_URL}/businessUpload/CloneAllDocuments`,
        params: {
          businessOrigin: businessId,
          businessDestiny: values.businessId,
          name: availableBusinesses?.find(
            (f: any) => f.businessId === values.businessId
          ).businessName,
          businessType: availableBusinesses?.find(
            (f) => f.businessId === values.businessId
          ).businessType,
          buyerType: availableBusinesses?.find(
            (f) => f.businessId === values.businessId
          ).buyerType,
          servicerType: availableBusinesses?.find(
            (f) => f.businessId === values.businessId
          ).servicerType,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Documents cloned successfully.', notistackSuccess)
          close()
          inputDataForm.resetForm({ values: initialValues })
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data, notistackError)
          inputDataForm.resetForm({ values: initialValues })
        })
    },
    onError: () => {
      enqueueSnackbar('Error on cloning documents.', notistackError)
    },
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {},
  })

  const getAvailableBusinesses = () => {
    if (
      relatedBusiness &&
      relatedBusiness !== null &&
      inputDataForm.values.clientId !== null
    ) {
      const rb = relatedBusiness.filter(
        (f: any) =>
          f.clientId === inputDataForm.values.clientId &&
          f.businessId !== businessId &&
          f.businessType !== SELLER
      )

      const sortedRelatedBusinesses = [...rb].sort((a, b) =>
        a.clientName.localeCompare(b.clientName)
      )
      return sortedRelatedBusinesses
    }

    if (relatedBusiness && relatedBusiness !== null) {
      const sortedRelatedBusinesses = [...relatedBusiness].sort((a, b) =>
        a.businessName.localeCompare(b.businessName)
      )
      return sortedRelatedBusinesses
    }

    return relatedBusiness
  }
  const availableBusinesses =
    relatedBusiness && relatedBusiness.length > 0
      ? getAvailableBusinesses()
      : []

  useEffect(() => {
    if (clients && clients.length === 1) {
      inputDataForm.setFieldValue('clientId', clients[0].clientId, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, setClients])

  return (
    <form onSubmit={inputDataForm.handleSubmit}>
      <Dialog
        PaperProps={{
          style: { width: 350 },
        }}
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Clone Documents
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          {(loading || cloneAllDocuments.isLoading) && (
            <Loader specificHeight={80} />
          )}
          <Grid container spacing={3} style={{ width: '100%' }}>
            {clients && clients.length > 1 && (
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  data-cy="dialog-client-dropdown"
                  id="select-client"
                  select
                  label="Client"
                  fullWidth
                  name="clientId"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  error={!!inputDataForm.errors.clientId}
                  value={inputDataForm.values.clientId}
                  helperText={inputDataForm.errors.clientId}
                >
                  <MenuItem value="">
                    <em>Select a client</em>
                  </MenuItem>
                  {clients?.map((c: any) => {
                    return (
                      <MenuItem key={`${c.clientId}`} value={c.clientId}>
                        <Typography>{c.clientName}</Typography>
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                data-cy="dialog-business-dropdown"
                id="select-business"
                select
                fullWidth
                label="Business"
                name="businessId"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={inputDataForm.handleChange}
                error={!!inputDataForm.errors.businessId}
                value={inputDataForm.values.businessId}
                helperText={inputDataForm.errors.businessId}
              >
                <MenuItem value="">
                  <em>Select a business</em>
                </MenuItem>
                {availableBusinesses?.map((b: any) => {
                  return (
                    <MenuItem key={`${b.businessId}`} value={b.businessId}>
                      <Typography>{b.businessName}</Typography>
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              inputDataForm.resetForm({ values: initialValues })
              close()
            }}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || availableBusinesses?.length === 0}
            onClick={() => {
              setLoading(true)
              handleSubmit()
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default CloneAllDocuments
