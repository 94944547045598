import styled from 'styled-components'

export const Root = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #f4f4f4;
  }
`

export const Selected = styled(Root)`
  border-bottom: 2px solid #3372c7;
`

export const Button = styled.span`
  color: #707070;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85rem;
  background: none;
  border: none;
  cursor: pointer;
`

export const ButtonSelected = styled(Button)`
  color: #3372c7;
  border-bottom: 2px solid #3372c7;
`
