/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { GetAccessToken } from 'src/api/axios/helper'
import { notistackOptions } from 'src/configs/notistackOptions'
import { BACKEND_URL } from 'src/api/axios/api'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useMutation, useQueryClient } from 'react-query'
import { GlobalContext } from 'src/context/GlobalContext'

import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from '../GridCommandCell/style'

interface IFillableActions {
  formType: string
  status: string
  fillableFormId: number
  loading: boolean
  inputDataForm: any
  isDirty: boolean
  canInternalSave: boolean
}

const FillableActions: React.FC<IFillableActions> = ({
  formType,
  status,
  fillableFormId,
  loading,
  inputDataForm,
  isDirty,
  canInternalSave,
}: IFillableActions) => {
  const { enqueueSnackbar } = useSnackbar()
  const [openRejectDialog, setOpenRejectDialog] = useState<boolean>(false)

  const [rejectionReason, setRejectionReason] = useState<string>('')
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const queryClient = useQueryClient()
  const { setLoading } = useContext(GlobalContext)

  const mutationAcceptRejectFillableForm = useMutation({
    mutationFn: async (statusForm: string) => {
      return axios({
        method: 'put',
        url: `${BACKEND_URL}/FillableDocument`,
        params: {
          fillableFormId,
          status: statusForm,
          comment: rejectionReason,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async (data) => {
      enqueueSnackbar(
        `Document ${data.config.params.status || 'updated'}.`,
        notistackSucces
      )

      queryClient.refetchQueries({
        queryKey: 'getFillableDocument',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })

      queryClient.refetchQueries({ queryKey: 'getFillableForm' })

      setOpenRejectDialog(false)
    },
    onError: (error: any) => {
      enqueueSnackbar(error?.response.data, notistackError)
      setTimeout(() => {
        setLoading(false)
      }, 2000)
    },
  })

  const RejectForm = () => {
    setLoading(true)
    mutationAcceptRejectFillableForm.mutate('Rejected')
  }

  const AcceptForm = () => {
    setLoading(true)
    mutationAcceptRejectFillableForm.mutate('Accepted')
  }

  return (
    <>
      {isInternal && canInternalSave && (
        <Box display="flex" flexDirection="row">
          {status === 'Pending Acceptance' && (
            <>
              <Box
                ml={
                  [
                    'user',
                    'executives',
                    'insurance',
                    'media-settings',
                  ].includes(formType)
                    ? 2
                    : 0
                }
              >
                <Button
                  onClick={() => setOpenRejectDialog(true)}
                  variant="contained"
                  color="primary"
                  disabled={loading || isDirty}
                  size="small"
                >
                  Reject
                </Button>
              </Box>

              <Box ml={2}>
                <Button
                  size="small"
                  onClick={AcceptForm}
                  disabled={loading || isDirty}
                  variant="contained"
                  color="primary"
                >
                  Accept
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
      <Dialog
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        aria-labelledby="reject-dialog-title"
        aria-describedby="reject-dialog-description"
        maxWidth="md"
        PaperProps={{ style: { width: '500px' } }}
      >
        <DialogTitleStyled id="reject-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Rejection Reason
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenRejectDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <TextField
            multiline
            className="custom-textfieldMultiline-label"
            minRows={5}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 400 }}
            name="reason"
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Reason"
          />
          <Typography
            style={{
              color: 'silver',
              textAlign: 'right',
            }}
          >
            {`${rejectionReason.length}/400`}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpenRejectDialog(false)
              setRejectionReason('')
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!rejectionReason}
            onClick={() => {
              setOpenRejectDialog(false)
              RejectForm()
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FillableActions
