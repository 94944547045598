import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'

interface CircularProgressWithLabelProps {
  progressValue: number
  title: string
  color: string
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  progressValue,
  title,
  color,
}: CircularProgressWithLabelProps) => {
  return (
    <Tooltip title={title}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={progressValue}
          style={{ color }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            style={{ fontWeight: 'bold' }}
            component="div"
            color="textPrimary"
          >
            {`${parseFloat(progressValue.toString()).toFixed(0)}%`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default CircularProgressWithLabel
