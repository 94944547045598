/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import axios from 'axios'
import * as React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { BACKEND_URL } from 'src/api/axios/api'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { GetAccessToken } from 'src/api/axios/helper'

import { useState } from 'react'
import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from './style'
import { ConfirmDialog } from '../Dialogs'

interface QuestionCommandCellProps {
  dataItem: any
  confirmCallback: () => void
  updateSuccededCallback: () => void
}

interface UpdateQuestionForm {
  id: number
  enabled: boolean
  statement: string
}

const QuestionCommandCell: React.FC<QuestionCommandCellProps> = ({
  dataItem,
  confirmCallback,
  updateSuccededCallback,
}: QuestionCommandCellProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = React.useState<UpdateQuestionForm>({
    id: dataItem.id,
    enabled: dataItem.enabled,
    statement: dataItem.statement,
  })

  const inputDataFormSchema = Yup.object().shape({
    enabled: Yup.bool().required('Required'),
    statement: Yup.string().nullable().required('Required'),
  })

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id,
        enabled: values.enabled,
        statement: values.statement,
      }
      setLoading(true)
      axios({
        method: 'put',
        url: `${BACKEND_URL}/Question`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Data updated', notistackSucces)
          setLoading(false)
          updateSuccededCallback()
        })
        .catch((error) => {
          setLoading(false)
          enqueueSnackbar(error.response.data, notistackError)
        })

      setSubmitting(false)
    },
  })

  return (
    <>
      <td className="k-command-cell">
        <IconButton
          data-cy="grid-delete-button"
          onClick={() => {
            setOpenDeleteDialog(true)
          }}
        >
          <Icon name="Delete" fontSize="small" />
        </IconButton>
        <IconButton
          data-cy="grid-edit-button"
          onClick={() => {
            setOpenEditDialog(true)
          }}
        >
          <Icon name="Edit" fontSize="small" />
        </IconButton>
      </td>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Question
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" fontSize="small" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <form onSubmit={inputDataForm.handleSubmit}>
            {loading && loadingPanel}
            <Grid container style={{ width: '400px' }}>
              <Grid container direction="column" spacing={3}>
                <Grid
                  item
                  data-cy="dialog-disable-enabled-selector"
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Disabled</Grid>
                  <Grid item>
                    <Switch
                      checked={inputDataForm.values.enabled}
                      onChange={inputDataForm.handleChange}
                      name="enabled"
                    />
                  </Grid>
                  <Grid item>Enabled</Grid>
                </Grid>
                <Grid item>
                  <TextField
                    data-cy="dialog-input-field-statement"
                    fullWidth
                    multiline
                    className="custom-textfieldMultiline-label"
                    minRows={3}
                    variant="outlined"
                    label="Statement"
                    name="statement"
                    style={{ width: '400px' }}
                    value={inputDataForm.values.statement}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.statement}
                    helperText={inputDataForm.errors.statement}
                    inputProps={{
                      maxLength: 500,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: '6px' }}
                spacing={1}
              >
                <Grid item>
                  <Button
                    data-cy="cancel-button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenEditDialog(false)
                      inputDataForm.resetForm({ values: initialValues })
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-cy="update-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!inputDataForm.dirty || !inputDataForm.isValid}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          title="Delete"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Would you like to delete this question?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            confirmCallback()
            setOpenDeleteDialog(false)
          }}
        />
      )}
    </>
  )
}

export default QuestionCommandCell
