import React, { useContext, useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'

import { maskPhoneNumber } from 'src/utils/masker'
import { isUkCountry } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DatePicker } from 'everchain-uilibrary'

interface IBusinessinformation {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
}

const Businessinformation: React.FC<IBusinessinformation> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: IBusinessinformation) => {
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const [samePhysicalAddress, setSamePhysicalAddress] = useState<boolean>()

  const [samePhysicalAddressToBilling, setSamePhysicalAddressToBilling] =
    useState<boolean>()

  useEffect(() => {
    setSamePhysicalAddress(
      inputDataForm.values.samePhysicalAddress === undefined
        ? true
        : inputDataForm.values.samePhysicalAddress
    )

    setSamePhysicalAddressToBilling(
      inputDataForm.values.samePhysicalAddressToBilling === undefined
        ? true
        : inputDataForm.values.samePhysicalAddressToBilling
    )
  }, [inputDataForm])
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ margin: '6px 6px 6px 6px', width: '800px' }}
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-companyname"
            label="Company Name"
            name="companyName"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.companyName}
            error={!!inputDataForm.errors.companyName}
            helperText={inputDataForm.errors.companyName}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-physicalAddress"
            label="Physical Address"
            name="physicalAddress"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.physicalAddress}
            error={!!inputDataForm.errors.physicalAddress}
            helperText={inputDataForm.errors.physicalAddress}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-city"
            label="City"
            name="city"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.city}
            error={!!inputDataForm.errors.city}
            helperText={inputDataForm.errors.city}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-state"
            label={isUk ? 'County' : 'State'}
            name="state"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.state}
            error={!!inputDataForm.errors.state}
            helperText={inputDataForm.errors.state}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-zipcode"
            label={isUk ? 'Postal Code' : 'Zip Code'}
            name="zipcode"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 10,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.zipcode}
            error={!!inputDataForm.errors.zipcode}
            helperText={inputDataForm.errors.zipcode}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                data-cy="same-as-physical-checkbox"
                checked={inputDataForm.values.samePhysicalAddress ?? true}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, !samePhysicalAddress, false)
                }}
                name="samePhysicalAddress"
                color="primary"
                disabled={readOnly}
              />
            }
            label="Same as Physical Address"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            disabled={readOnly || samePhysicalAddress}
            fullWidth
            data-cy="dialog-input-field-mailingAddress"
            label={isUk ? 'Postal Address' : 'Mailing Address'}
            name="mailingAddress"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.mailingAddress}
            error={!!inputDataForm.errors.mailingAddress}
            helperText={inputDataForm.errors.mailingAddress}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddress}
            fullWidth
            data-cy="dialog-input-field-mailing-city"
            label={isUk ? 'Postal City' : 'Mailing City'}
            name="mailingCity"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.mailingCity}
            error={!!inputDataForm.errors.mailingCity}
            helperText={inputDataForm.errors.mailingCity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddress}
            fullWidth
            data-cy="dialog-input-field-mailing-state"
            label={isUk ? 'Postal County' : 'Mailing State'}
            name="mailingState"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.mailingState}
            error={!!inputDataForm.errors.mailingState}
            helperText={inputDataForm.errors.mailingState}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddress}
            fullWidth
            data-cy="dialog-input-field-mailing-zipcode"
            label={isUk ? 'Postal Code' : 'Mailing Zip Code'}
            name="mailingZipcode"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 10,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.mailingZipcode}
            error={!!inputDataForm.errors.mailingZipcode}
            helperText={inputDataForm.errors.mailingZipcode}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                data-cy="same-as-physical-toBilling-checkbox"
                checked={
                  inputDataForm.values.samePhysicalAddressToBilling ?? true
                }
                onChange={({ target: { value, name } }) => {
                  handleChange(name, !samePhysicalAddressToBilling, false)
                }}
                name="samePhysicalAddressToBilling"
                color="primary"
                disabled={readOnly}
              />
            }
            label="Same as Physical Address"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextField
            disabled={readOnly || samePhysicalAddressToBilling}
            fullWidth
            data-cy="dialog-input-field-billing-Address"
            label="Billing Address"
            name="billingAddress"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.billingAddress}
            error={!!inputDataForm.errors.billingAddress}
            helperText={inputDataForm.errors.billingAddress}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddressToBilling}
            fullWidth
            data-cy="dialog-input-field-billing-city"
            label="Billing City"
            name="billingCity"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.billingCity}
            error={!!inputDataForm.errors.billingCity}
            helperText={inputDataForm.errors.billingCity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddressToBilling}
            fullWidth
            data-cy="dialog-input-field-billing-state"
            label="Billing State"
            name="billingState"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.billingState}
            error={!!inputDataForm.errors.billingState}
            helperText={inputDataForm.errors.billingState}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly || samePhysicalAddressToBilling}
            fullWidth
            data-cy="dialog-input-field-billing-zipcode"
            label="Billing Zip Code"
            name="billingZipcode"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 10,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.billingZipcode}
            error={!!inputDataForm.errors.billingZipcode}
            helperText={inputDataForm.errors.billingZipcode}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-consumerCustomerServiceTelephone"
            label="Consumer Customer Service Telephone #"
            name="consumerServiceTelephone"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              const masked = maskPhoneNumber(value, country)
              handleChange(name, masked, false)
            }}
            value={inputDataForm.values.consumerServiceTelephone}
            error={!!inputDataForm.errors.consumerServiceTelephone}
            helperText={inputDataForm.errors.consumerServiceTelephone}
            placeholder={`${!isUk ? '(000) 000-0000' : ''}`}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-companyWebsiteAddress"
            label="Company Website Address"
            name="companyWebsiteAddress"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.companyWebsiteAddress || null}
            error={!!inputDataForm.errors.companyWebsiteAddress}
            helperText={inputDataForm.errors.companyWebsiteAddress}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            country={country}
            disabled={readOnly}
            style={{ width: '100%' }}
            data-cy="dialog-input-field-dateofEstablishment"
            id="dateOfEstablishment"
            label="Date of Establishment"
            name="dateOfEstablishment"
            value={inputDataForm.values.dateOfEstablishment || null}
            errorMessage={inputDataForm.errors.dateOfEstablishment?.toString()}
            onChange={(date) => {
              handleChange('dateOfEstablishment', date, true)
            }}
          />
        </Grid>
        {!isUk && (
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-stateofIncorporation"
              label="State of Incorporation"
              name="stateofIncorporation"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.stateofIncorporation}
              error={!!inputDataForm.errors.stateofIncorporation}
              helperText={inputDataForm.errors.stateofIncorporation}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-taxID"
            label={isUk ? 'Company Registration Number' : 'Tax ID #'}
            name="taxID"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: isUk ? 8 : 20,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.taxID}
            error={!!inputDataForm.errors.taxID}
            helperText={inputDataForm.errors.taxID}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-businessType"
            label={isUk ? 'Business Type' : 'Business Type (LLC)'}
            name="businessType"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.businessType}
            error={!!inputDataForm.errors.businessType}
            helperText={inputDataForm.errors.businessType}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Typography variant="h6">Contact Information</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="HQ Phone"
            name="hqphone"
            data-cy="dialog-input-field-HQ-Phone"
            inputProps={{ maxlength: 20 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              const masked = maskPhoneNumber(value, country)
              handleChange(name, masked, false)
            }}
            value={inputDataForm.values.hqphone}
            error={!!inputDataForm.errors.hqphone}
            helperText={inputDataForm.errors.hqphone}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="Customer Service E-mail"
            name="customerServiceEmail"
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="dialog-input-field-customer_Service_Email"
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.customerServiceEmail}
            error={!!inputDataForm.errors.customerServiceEmail}
            helperText={inputDataForm.errors.customerServiceEmail}
            disabled={readOnly}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Typography variant="h6">Primary Contact</Typography>
        </Grid>

        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="Last Name"
            name="primaryContact_LastName"
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="dialog-input-field-primaryContact_LastName"
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.primaryContact_LastName}
            error={!!inputDataForm.errors.primaryContact_LastName}
            helperText={inputDataForm.errors.primaryContact_LastName}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="First Name"
            name="primaryContact_FirstName"
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="dialog-input-field-primaryContact_FirstName"
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.primaryContact_FirstName}
            error={!!inputDataForm.errors.primaryContact_FirstName}
            helperText={inputDataForm.errors.primaryContact_FirstName}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="E-mail"
            name="primaryContact_EMail"
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="dialog-input-field-primaryContact_EMail"
            onChange={({ target: { value, name } }) => {
              handleChange(name, value, false)
            }}
            value={inputDataForm.values.primaryContact_EMail}
            error={!!inputDataForm.errors.primaryContact_EMail}
            helperText={inputDataForm.errors.primaryContact_EMail}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="Direct (Phone)"
            name="primaryContact_OfficePhone"
            data-cy="dialog-input-field-primaryContact_OfficePhone"
            inputProps={{ maxlength: 20 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              const masked = maskPhoneNumber(value, country)
              handleChange(name, masked, false)
            }}
            value={inputDataForm.values.primaryContact_OfficePhone}
            error={!!inputDataForm.errors.primaryContact_OfficePhone}
            helperText={inputDataForm.errors.primaryContact_OfficePhone}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            fullWidth
            label="Mobile"
            name="primaryContact_MobilePhone"
            InputLabelProps={{
              shrink: true,
            }}
            data-cy="dialog-input-field-primaryContact_MobilePhone"
            inputProps={{ maxlength: 20 }}
            onChange={({ target: { value, name } }) => {
              const masked = maskPhoneNumber(value, country)
              handleChange(name, masked, false)
            }}
            value={inputDataForm.values.primaryContact_MobilePhone}
            error={!!inputDataForm.errors.primaryContact_MobilePhone}
            helperText={inputDataForm.errors.primaryContact_MobilePhone}
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Businessinformation
