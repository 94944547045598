import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DialogTitleStyled } from '../../../pages/NewDocumentRegistration/style'

interface DeleteFileModalProps {
  open: boolean
  documentId: number
  Close: () => void
}

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  open,
  documentId,
  Close,
}: DeleteFileModalProps) => {
  const userToken = GetAccessToken()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const notistackSuccess = notistackOptions('success')
  const notistackError = notistackOptions('error')

  useEffect(() => {
    if (open) setOpenDialog(true)
    else setOpenDialog(false)
  }, [open])

  const mutationDeleteFile = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'delete',
        url: `${BACKEND_URL}/businessupload`,
        params: {
          documentUploadedId: documentId,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Document has been deleted.', notistackSuccess)

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })

      Close()
    },
    onError: () => {
      enqueueSnackbar('Error on deleting document.', notistackError)
    },
  })

  const handleDeleteDocument = () => {
    mutationDeleteFile.mutate()
  }

  return (
    <>
      <Dialog open={openDialog} onClose={() => Close()} maxWidth="md">
        <DialogTitleStyled id="dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Delete Document
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => Close()}
            disabled={mutationDeleteFile.isLoading}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Typography>Would you like to delete this document?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => Close()}
            disabled={mutationDeleteFile.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteDocument}
            disabled={mutationDeleteFile.isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteFileModal
