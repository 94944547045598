import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {
  CssBaseline,
  unstable_createMuiStrictModeTheme,
  responsiveFontSizes,
  IconButton,
  ThemeProvider as Mui5ThemeProvider,
} from '@mui/material'

import { makeStyles } from '@mui/styles'
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'

import { GlobalStyle, defaultTheme } from 'src/styles'

import { QueryClient, QueryClientProvider } from 'react-query'
import {
  QueryClient as NewQueryClient,
  QueryClientProvider as NewQueryClientProvider,
} from '@tanstack/react-query'

import { Colors, Icon } from 'everchain-uilibrary'
import IdleTimeout from './components/IdleTimeout'
import SessionExpiringModal from './components/SessionTimeout'
import App from './App'
import { AuthProvider } from './context/AuthenticationContext'
import { GlobalProvider } from './context/GlobalContext'

const createMuiTheme = unstable_createMuiStrictModeTheme

const theme = createMuiTheme(defaultTheme)
const themeResponsiveFonts = responsiveFontSizes(theme)

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const useStyle = makeStyles({
  success: { backgroundColor: `${Colors.blue} !important` },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' },
  root: {
    top: 75,
  },
})

const DismissAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton key="close" color="inherit" onClick={() => closeSnackbar(id)}>
      <Icon name="Close" />
    </IconButton>
  )
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Colors.blue,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Colors.error,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Colors.warning,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Colors.info,
  },
}))

const Root: React.FC = () => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  const newQueryClient = new NewQueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  return (
    <NewQueryClientProvider client={newQueryClient}>
      <QueryClientProvider client={queryClient}>
        <Mui5ThemeProvider theme={themeResponsiveFonts}>
          <ThemeProvider theme={themeResponsiveFonts}>
            <CssBaseline />
            <GlobalStyle />
            <Router>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                hideIconVariant={true}
                action={(key) => <DismissAction id={key} />}
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  warning: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
              >
                <SessionExpiringModal>
                  <IdleTimeout>
                    <GlobalProvider>
                      <AuthProvider>
                        <App />
                      </AuthProvider>
                    </GlobalProvider>
                  </IdleTimeout>
                </SessionExpiringModal>
              </SnackbarProvider>
            </Router>
          </ThemeProvider>
        </Mui5ThemeProvider>
      </QueryClientProvider>
    </NewQueryClientProvider>
  )
}

export default Root
