import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'

import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { notistackOptions } from 'src/configs/notistackOptions'

import { BusinessContext } from 'src/context/BusinessContext'

import { Icon } from 'everchain-uilibrary'
import { DialogTitleStyled } from '../../../pages/NewDocumentRegistration/style'

interface TransferFileDialogProps {
  open: boolean
  action: string
  documentId: number
  fileData: any
  allowAudioFile: boolean
  Close: () => void
}

const TransferFileDialog: React.FC<TransferFileDialogProps> = ({
  open,
  action,
  documentId,
  fileData,
  allowAudioFile,
  Close,
}: TransferFileDialogProps) => {
  const userToken = GetAccessToken()
  const businessContext = useContext<any>(BusinessContext)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const notistackSuccess = notistackOptions('success')
  const notistackError = notistackOptions('error')

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isCloneOperation, setIsCloneOperation] = useState<boolean>(false)
  const [categorySelected, setCategorySelected] = useState<any>()
  const [documentFolderSelected, setDocumentFolderSelected] = useState<any>()

  useEffect(() => {
    if (action === 'Clone') setIsCloneOperation(true)
    else setIsCloneOperation(false)
  }, [action])

  useEffect(() => {
    if (open) setOpenDialog(true)
    else setOpenDialog(false)
  }, [open])

  const buyerType = queryClient.getQueryData(['buyerType', businessContext])

  const queryCategoryData = useQuery({
    queryKey: 'fileTransferCategoryData',
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
        params: {
          isEnabled: true,
          businessType: businessContext.businessType,
          buyerType: buyerType || '',
        },
      }).then((result: any) => {
        return result.data.categories
      }),
  })

  const categoryData = queryCategoryData?.data?.sort((a: any, b: any) =>
    a.name.localeCompare(b.name)
  )

  const queryDocumentFolderData = useQuery({
    enabled: !!categorySelected?.id,
    queryKey: ['fileTransferDocumentFolderData', categorySelected],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/documents`,
        params: {
          categoryId: categorySelected.id,
          businessType: businessContext.businessType,
          isEnabled: true,
          businessId: businessContext.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data.documents
      }),
  })

  const documentFolderData = queryDocumentFolderData?.data
    ?.filter(
      (t: any) => t.id !== documentId && t.allowAudioFile === allowAudioFile
    )
    .sort((a: any, b: any) => a.name.localeCompare(b.name))

  const mutationFileTransfer = useMutation({
    mutationFn: async () =>
      axios({
        method: 'patch',
        url: `${BACKEND_URL}/BusinessUpload`,
        params: {
          documentUploadedId: fileData.id,
          documentFolderId: documentFolderSelected.id,
          clone: isCloneOperation,
        },
        headers: {
          Authorization: userToken,
        },
      }),
    onSuccess: () => {
      enqueueSnackbar(
        `Document has been ${isCloneOperation ? 'cloned' : 'transferred'}.`,
        notistackSuccess
      )
      setCategorySelected({})
      setDocumentFolderSelected({})

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      handleClose()
    },
    onError: () => {
      enqueueSnackbar(
        `Error on ${isCloneOperation ? 'clone' : 'transfer'} document.`,
        notistackError
      )
    },
  })

  const handleCategoryTransfer = () => {
    mutationFileTransfer.mutate()
  }

  const handleClose = () => {
    setCategorySelected(undefined)
    setDocumentFolderSelected(undefined)
    Close()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="lg"
      PaperProps={{ style: { width: '400px' } }}
    >
      <DialogTitleStyled id="dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          {isCloneOperation ? 'Clone Document' : 'Transfer Document'}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
          disabled={mutationFileTransfer.isLoading}
        >
          <Icon name="Close" />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography>Select a category:</Typography>
            {queryCategoryData && queryCategoryData.isLoading ? (
              <Skeleton />
            ) : (
              <TextField
                select
                id="select-category"
                value={categorySelected?.name ?? ''}
                fullWidth
                style={{ minWidth: '250px' }}
                disabled={
                  mutationFileTransfer.isLoading ||
                  queryCategoryData.isLoading ||
                  queryDocumentFolderData.isLoading
                }
              >
                <MenuItem value="" disabled={true}>
                  <em>Select category</em>
                </MenuItem>

                {categoryData.map((doc: any) => {
                  return (
                    <MenuItem
                      key={doc.id}
                      value={doc.name}
                      onClick={() => {
                        setCategorySelected(doc)
                      }}
                    >
                      {doc.name}
                    </MenuItem>
                  )
                })}
              </TextField>
            )}
          </Box>
          <Box mt={6}>
            <Typography>Select a document folder:</Typography>
            {documentFolderData?.isLoading ? (
              <Skeleton />
            ) : (
              <TextField
                select
                id="select-document"
                value={documentFolderSelected?.name ?? ''}
                fullWidth
                style={{ minWidth: '250px' }}
                disabled={
                  mutationFileTransfer.isLoading ||
                  queryCategoryData.isLoading ||
                  queryDocumentFolderData.isLoading ||
                  !categorySelected
                }
              >
                <MenuItem value="" disabled={true}>
                  <em>Select document folder</em>
                </MenuItem>

                {categorySelected &&
                  documentFolderData &&
                  documentFolderData.map((doc: any) => {
                    return (
                      <MenuItem
                        key={doc.id}
                        value={doc.name}
                        onClick={() => {
                          setDocumentFolderSelected(doc)
                        }}
                      >
                        {doc.name}
                      </MenuItem>
                    )
                  })}
              </TextField>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={mutationFileTransfer.isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !documentFolderSelected ||
            mutationFileTransfer.isLoading ||
            queryCategoryData.isLoading ||
            queryDocumentFolderData.isLoading
          }
          onClick={handleCategoryTransfer}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransferFileDialog
