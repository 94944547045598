import React, { useContext } from 'react'
import { Box, Card, CardHeader, Grid, TextField } from '@mui/material'

import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'

interface IDisclosureAuthorization {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
}

const DisclosureAuthorization: React.FC<IDisclosureAuthorization> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: IDisclosureAuthorization) => {
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  return (
    <>
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader
          subheader={
            <Box>
              <Box>
                I hereby certify that all information provided in this
                Application is true and correct. I understand that incorrect or
                misleading information or documentation, or an omission or
                failure to include all relevant information, may result in
                rejection of my application or may be grounds for denial of
                membership by EverChain to EverChain.com®.
              </Box>
              <Box mt={2}>
                All reports and applications are confidential and are utilized
                by EverChain.com solely for membership decisions. Furthermore,
                all applications and any reports obtained in conjunction with
                this Debt Buyer Application shall be kept in a secure location
                in compliance with proper and necessary data security industry
                standards and regulations.
              </Box>
            </Box>
          }
        />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '98%' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-fullName"
              label="Full Name"
              name="fullName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.fullName}
              error={!!inputDataForm.errors.fullName}
              helperText={inputDataForm.errors.fullName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-title"
              label="Title"
              name="title"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value, false)
              }}
              value={inputDataForm.values.title}
              error={!!inputDataForm.errors.title}
              helperText={inputDataForm.errors.title}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              disabled={readOnly}
              country={country}
              style={{ width: '100%' }}
              id="formDate"
              label="Date"
              maxDate={new Date()}
              name="formDate"
              value={inputDataForm.values.formDate || null}
              errorMessage={inputDataForm.errors.formDate?.toString()}
              onChange={(date: any) => {
                handleChange('formDate', date, true)
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default DisclosureAuthorization
