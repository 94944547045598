import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import DeleteIcon from '@mui/icons-material/Delete'
import { maskMoney } from 'src/utils/masker'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import axios from 'axios'

import { FilesToUpload, isUkCountry } from 'src/utils/common'
import { GlobalContext } from 'src/context/GlobalContext'
import { MaxFileSize } from 'src/utils/constants'
import { DatePicker, Icon } from 'everchain-uilibrary'
import UploadDragDrop from '../UploadDragDrop'
import { UploadItem } from '../UploadDragDrop/styles'
import { ConfirmDialog } from '../Dialogs'

const MAX_SIZE_FILE = MaxFileSize

interface IInsurance {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  handleBlur: (name: any, value: any) => void
  selectItem: (id: string) => void
  fillableForms: any
  selectedItem: any
  readOnly: boolean
  setSupportingDocuments: (file: FilesToUpload[]) => void
  filesUploaded: FilesToUpload[] | null
  handleRemoveFile: (index: number) => void
  handleRemoveSupportingDocument: (id: number) => void
  deleteFormItem: (id: string) => void
}

const Insurance: React.FC<IInsurance> = ({
  handleChange,
  inputDataForm,
  handleBlur,
  selectItem,
  fillableForms,
  selectedItem,
  readOnly,
  setSupportingDocuments,
  filesUploaded,
  handleRemoveFile,
  handleRemoveSupportingDocument,
  deleteFormItem,
}: IInsurance) => {
  const { enqueueSnackbar } = useSnackbar()
  const { loading, setLoading } = useContext(GlobalContext)
  const { userPermissions, profileClient } = useContext(AuthContext)
  const [insuranceToRemove, setInsuranceToRemove] = useState('')
  const files = filesUploaded
  const [docToRemove, setDocToRemove] = useState<number | undefined>()
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openDeleteInsuranceDialog, setOpenDeleteInsuranceDialog] =
    useState<boolean>(false)

  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const userToken = GetAccessToken()

  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    if (acceptedFiles !== null && acceptedFiles && acceptedFiles.length > 0) {
      const newFile: any[] = []

      acceptedFiles.forEach((f: File) => {
        newFile.push({ section: 'proofOfInsurance', file: f })
      })

      setSupportingDocuments(newFile)
      handleChange('proofOfInsurance', 'proofOfInsurance', false)
    }
  }

  const handleUploadFileRemove = (index: number) => {
    if (filesUploaded) {
      handleRemoveFile(index)
      if (selectedItem !== null && selectedItem.supportingDocuments !== null) {
        handleChange('proofOfInsurance', 'proofOfInsurance', false)
      } else {
        handleChange('proofOfInsurance', null, false)
      }
    }
  }

  const DeleteItemForm = (id: string) => {
    setOpenDeleteInsuranceDialog(false)
    deleteFormItem(id)
  }

  const handleDownloadSupportingDocument = (
    blobUri: string,
    fileName: string
  ) => {
    setLoading(true)
    axios({
      method: 'get',
      url: `${BACKEND_URL}/fillableDocument/GetSupportingDocument`,
      headers: {
        Authorization: userToken,
      },
      params: {
        fileBlobUri: blobUri,
        supportingFileName: `${inputDataForm.values.insuranceProvider}_ProofOfInsurance_${fileName}`,
      },
    })
      .then((result: any) => {
        setLoading(false)
        window.location.href = result.data
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <Box display="flex" flexDirection="row" width="60em">
        <List
          style={{ minWidth: '200px' }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Insurances Submitted
            </ListSubheader>
          }
        >
          {fillableForms &&
            fillableForms.map((x: any) => (
              <ListItem
                key={x.id}
                selected={
                  x.id ===
                  (selectedItem && selectedItem?.id !== null
                    ? selectedItem?.id
                    : null)
                }
              >
                <Box
                  borderColor="blue"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                    >
                      <Tooltip
                        placement="right-end"
                        title={
                          x.status === 'Pending Upload'
                            ? 'Pending Submission (Rejected)'
                            : x.status
                        }
                      >
                        {x.status === 'Accepted' ? (
                          <Icon name="Check" fontSize="small" color="green" />
                        ) : x.status === 'Pending Upload' ? (
                          <Icon name="Close" fontSize="small" color="red" />
                        ) : (
                          <Icon
                            name="ErrorOutline"
                            fontSize="small"
                            color="gold"
                          />
                        )}
                      </Tooltip>
                    </Box>
                    <Box
                      style={{ cursor: 'pointer' }}
                      onClick={() => selectItem(x.id)}
                      ml={2}
                    >
                      {JSON.parse(x.formData)[0].insuranceProvider}
                    </Box>
                  </Box>
                  {x.status === 'Pending Acceptance' && (
                    <Box ml={2}>
                      <Tooltip title="Remove insurance.">
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            setInsuranceToRemove(x.id)
                            setOpenDeleteInsuranceDialog(true)
                          }}
                        >
                          <DeleteIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
        </List>
        <Box>
          <Card elevation={1} style={{ width: '100%' }}>
            <CardHeader
              title="Errors & Omissions Insurance"
              subheader={`EverChain requires a minimum of ${
                isUk ? '£' : '$'
              }1 million in coverage.`}
            />
            <Grid
              container
              direction="row"
              style={{ margin: '6px 6px 6px 6px', width: '780px' }}
              spacing={3}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  disabled={readOnly}
                  data-cy="dialog-input-field-insuranceProvider"
                  label="Insurance provider"
                  name="insuranceProvider"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={({ target: { value, name } }) => {
                    handleChange(name, value, false)
                  }}
                  value={inputDataForm.values.insuranceProvider || ''}
                  error={!!inputDataForm.errors.insuranceProvider}
                  helperText={inputDataForm.errors.insuranceProvider}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  disabled={readOnly}
                  data-cy="dialog-input-field-limitCoverage"
                  label="Limit of coverage"
                  name="limitCoverage"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 14,
                  }}
                  onChange={({ target: { value, name } }) => {
                    const masked = maskMoney(value)
                    inputDataForm.setFieldValue(name, masked, false)
                    handleChange(name, masked, false)
                  }}
                  onBlur={({ target: { value, name } }) => {
                    const masked = maskMoney(value)
                    handleBlur(name, masked)
                  }}
                  value={inputDataForm.values.limitCoverage || ''}
                  error={!!inputDataForm.errors.limitCoverage}
                  helperText={inputDataForm.errors.limitCoverage}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  disabled={readOnly}
                  data-cy="dialog-input-field-policy"
                  label="Policy"
                  name="policy"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={({ target: { value, name } }) => {
                    handleChange(name, value, false)
                  }}
                  value={inputDataForm.values.policy || ''}
                  error={!!inputDataForm.errors.policy}
                  helperText={inputDataForm.errors.policy}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disabled={readOnly}
                  style={{ width: '100%' }}
                  data-cy="dialog-input-field-startdatepolicy"
                  id="startDate"
                  label="Policy start date"
                  name="startDate"
                  value={inputDataForm.values.startDate || null}
                  errorMessage={inputDataForm.errors.startDate?.toString()}
                  country={country}
                  onChange={(date: any) => {
                    handleChange('startDate', date, true)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DatePicker
                  disabled={readOnly}
                  style={{ width: '100%' }}
                  data-cy="dialog-input-field-enddatepolicy"
                  id="endDate"
                  label="Policy end date"
                  name="endDate"
                  minDate={inputDataForm.values.startDate || null}
                  value={inputDataForm.values.endDate || null}
                  errorMessage={inputDataForm.errors.endDate?.toString()}
                  country={country}
                  onChange={(date: any) => {
                    handleChange('endDate', date, true)
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <Grid
            container
            direction="row"
            style={{ marginTop: '6px', width: '800px' }}
            spacing={3}
          >
            <Grid item xs={12} md={12}>
              <TextField
                disabled={readOnly}
                variant="outlined"
                fullWidth
                multiline
                className="custom-textfieldMultiline-label"
                rows={4}
                data-cy="dialog-input-field-additionalInsurance"
                label="Additional insurance"
                name="additionalInsurance"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 300,
                }}
                onChange={({ target: { value, name } }) => {
                  handleChange(name, value, false)
                }}
                placeholder={`Please identify any additional insurance policies your ${
                  isUk ? 'organisation' : 'organization'
                } currently has.`}
                value={inputDataForm.values.additionalInsurance || ''}
                error={!!inputDataForm.errors.additionalInsurance}
                helperText={inputDataForm.errors.additionalInsurance}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Typography>
                <b> Proof Of Insurance</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                display="flex"
                flexDirection="column"
                style={{ minHeight: 130 }}
              >
                <Box>
                  {isInternal && !inputDataForm.values.proofOfInsurance && (
                    <Typography variant="body2" color="textSecondary">
                      There is no file for this insurance.
                    </Typography>
                  )}

                  {inputDataForm.values.proofOfInsurance &&
                    (!files || files === null || files?.length === 0) && (
                      <List
                        style={{
                          height: '100px',
                          overflow: 'auto',
                          width: '100%',
                          marginTop: 0,
                          paddingTop: 0,
                          paddingBottom: 2,
                          lineHeight: '0.6rem',
                        }}
                      >
                        {selectedItem &&
                          selectedItem?.id &&
                          selectedItem.supportingDocuments &&
                          selectedItem.supportingDocuments?.map(
                            (doc: any, idx: number) => {
                              return (
                                <div key={`div_${idx}`}>
                                  <ListItem
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      width: '100%',
                                    }}
                                    alignItems="flex-start"
                                    key={`item_${idx}`}
                                  >
                                    <Box
                                      key={`Box${idx}`}
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="space-between"
                                      style={{ width: '100%', marginBottom: 2 }}
                                    >
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                      >
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                          component="span"
                                          key={`Typography_${doc.originalFileName}`}
                                        >
                                          {doc.originalFileName > 100
                                            ? `${doc.originalFileName.substring(
                                                0,
                                                95
                                              )}...`
                                            : doc.originalFileName}
                                        </Typography>
                                      </Box>
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                      >
                                        {!isInternal &&
                                          selectedItem.status !==
                                            'Accepted' && (
                                            <IconButton
                                              disabled={readOnly}
                                              size="small"
                                              key={`Button_Removedb${idx}`}
                                              onClick={() => {
                                                setDocToRemove(doc.id)
                                                setOpenDeleteDialog(true)
                                              }}
                                            >
                                              <DeleteIcon
                                                key={`DeleteButton_db${idx}`}
                                                className="upload-item-icon"
                                                fontSize="small"
                                              />
                                            </IconButton>
                                          )}

                                        {isInternal && (
                                          <Button
                                            size="small"
                                            key={`download${idx}`}
                                            data-cy={`download${idx}`}
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                            onClick={
                                              () =>
                                                handleDownloadSupportingDocument(
                                                  doc.blobUri,
                                                  doc.originalFileName
                                                )
                                              // eslint-disable-next-line react/jsx-curly-newline
                                            }
                                          >
                                            Download
                                          </Button>
                                        )}
                                      </Box>
                                    </Box>
                                  </ListItem>
                                  <Divider variant="fullWidth" component="li" />
                                </div>
                              )
                            }
                          )}
                      </List>
                    )}
                  {!isInternal &&
                  files &&
                  files !== null &&
                  files?.length > 0 ? (
                    <UploadItem
                      style={{ margin: 0, paddingTop: 0, width: '100%' }}
                    >
                      <Grid container style={{ width: '100%' }}>
                        <Grid item xs={12} md={12} style={{ width: '100%' }}>
                          <List
                            style={{
                              height: '100px',
                              overflow: 'auto',
                              width: '100%',
                              margin: 0,
                              paddingTop: 0,
                              lineHeight: '0.5rem',
                            }}
                          >
                            {files.map((file: any, idx: number) => {
                              return (
                                <ListItem
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    width: '100%',
                                  }}
                                  alignItems="flex-start"
                                  key={`item_${idx}`}
                                >
                                  <div
                                    className="upload-item-info"
                                    key={`div_${idx}`}
                                  >
                                    <DescriptionIcon
                                      key={`DescriptionIcon_${idx}`}
                                      fontSize="small"
                                      color="primary"
                                      className="upload-item-icon"
                                    />
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="span"
                                      key={`Typography_${file.file.name}`}
                                    >
                                      {file.file.name.length > 100
                                        ? `${file.file.name.substring(
                                            0,
                                            95
                                          )}...`
                                        : file.file.name}
                                    </Typography>
                                    <IconButton
                                      size="small"
                                      key={`Button_${idx}`}
                                      onClick={() => {
                                        handleUploadFileRemove(idx)
                                      }}
                                    >
                                      <DeleteIcon
                                        key={`DeleteButton_${idx}`}
                                        className="upload-item-icon"
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  </div>
                                </ListItem>
                              )
                            })}
                          </List>
                        </Grid>
                      </Grid>
                    </UploadItem>
                  ) : (
                    !isInternal &&
                    !readOnly && (
                      <UploadDragDrop
                        linkText="Upload File"
                        text=" - PDF files only (max. 200mb)"
                        size="small"
                        multiple={true}
                        onDrop={(acceptedFiles) => {
                          handleDroppedFiles(acceptedFiles)
                        }}
                        maxSize={MAX_SIZE_FILE}
                        onDropRejected={handleFileRejected}
                        accept="application/pdf"
                      />
                    )
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          title="Delete Document"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to delete this document ?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            docToRemove && handleRemoveSupportingDocument(docToRemove)
            setLoading(true)
            setOpenDeleteDialog(false)
          }}
        />
      )}

      {openDeleteInsuranceDialog && (
        <ConfirmDialog
          open={openDeleteInsuranceDialog}
          title="Remove Insurance"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to delete this insurance?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteInsuranceDialog(false)}
          onConfirm={() => DeleteItemForm(insuranceToRemove)}
        />
      )}
    </>
  )
}

export default Insurance
