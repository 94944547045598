import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import { notistackOptions } from 'src/configs/notistackOptions'
import { MaxFileSize, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { USER_ID } from 'src/configs/AuthService'
import { Delete, Description } from '@mui/icons-material'
import { UploadItem } from '../UploadDragDrop/styles'
import UploadDragDrop from '../UploadDragDrop'

interface ISOPVersioning {
  department: string
  sop: any
  isCompliance: boolean
}

const SOPVersioning: React.FC<ISOPVersioning> = ({
  sop,
  department,
  isCompliance,
}: ISOPVersioning) => {
  const userToken = GetAccessToken()
  const [files, setFiles] = useState<File[] | null>(null)
  const notistackSuccess = notistackOptions('success')
  const MAX_SIZE_FILE = MaxFileSize
  const notistackError = notistackOptions('error')
  const queryClient = useQueryClient()
  const ability = useContext(AbilityContext)

  const { user } = useContext(AuthContext)
  const userId = user.profile[USER_ID]

  const returnNextStep = (status: any) => {
    switch (status) {
      case 'under-review':
        return 'Send for final confirmation or upload new version.'
      case 'pending-final-confirmation':
        return 'Approve SOP ou return to review'
      case 'approved':
        return 'N/A'
      default:
        return 'Review SOP'
    }
  }

  const [sopToDownload, setSopToDownload] = useState<any | null>(null)

  const SendToFinalConfirmation = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/corporateSOP/SendToFinalConfirmation`,
        params: {
          id: sop.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP sent to final confirmation', notistackSuccess)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPCards',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOP',
          })
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on sending to final confirmation', notistackError)
    },
  })

  const SendToReview = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/corporateSOP/SendToReview`,
        params: {
          id: sop.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP returned to review.', notistackSuccess)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPCards',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOP',
          })
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on sending to review.', notistackError)
    },
  })

  const ApproveSOP = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/corporateSOP/ApproveSOP`,
        params: {
          id: sop.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP approved.', notistackSuccess)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPCards',
          })
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOP',
          })
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
        })
    },
    onError: () => {
      enqueueSnackbar('Error on approving SOP.', notistackError)
    },
  })

  const UploadSOPMutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData()

      files?.forEach((file: File) => {
        formData.append('files', file, file.name)
      })

      return axios({
        method: 'post',
        url: `${BACKEND_URL}/corporateSOP/UploadSOPRedline`,
        data: formData,
        params: {
          id: sop.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('New version uploaded.', notistackSuccess)
          queryClient.refetchQueries({
            queryKey: 'getSOPDrafts',
          })
          setFiles(null)
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on uploading SOP.', notistackError)
    },
  })

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }

  const handleUpload = () => {
    UploadSOPMutation.mutate()
  }

  const handleUploadFileRemove = (index: number) => {
    if (files) {
      setFiles(files.filter((f) => f.name !== files[index].name))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const downloadLastSOPVersion = useQuery({
    queryKey: ['downloadLastSOPVersion', sopToDownload],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/corporateSOP/downloadLastSOPVersion`,
        params: {
          id: sopToDownload,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSopToDownload(null)
        window.location.href = result.data || ''
      })
    },
    enabled: sopToDownload !== null,
  })

  const handleDownloadFile = () => {
    setSopToDownload(sop.id)
  }

  const handleSendToFinalConfirmation = () => {
    SendToFinalConfirmation.mutate()
  }

  const handleSendToReview = () => {
    SendToReview.mutate()
  }

  const handleApproveSOP = () => {
    ApproveSOP.mutate()
  }

  const nextStep = returnNextStep(sop.status)

  const loading =
    UploadSOPMutation.isLoading ||
    ApproveSOP.isLoading ||
    SendToReview.isLoading ||
    SendToFinalConfirmation.isLoading

  const canDownloadLatestVersion = ability.can(
    PermissionCodeAccess.ComplianceManagementCorporateSOP_Download,
    'any'
  )

  const canCreateRedline =
    department === sop.department &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementCorporateSOP_Redline,
      'any'
    )

  const canSendToFinalConfirmation =
    userId === sop.ownerId &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementCorporateSOP_Upload,
      'any'
    )

  const canApproveOrReturnToReview =
    isCompliance &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementCorporateSOP_Approve,
      'any'
    )

  return (
    <>
      <Box padding={5} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle2">SOP Draft</Typography>
          </Grid>
          <Paper style={{ padding: 5, width: '100%', minHeight: '210px' }}>
            {loading && <Loader />}
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body2">
                  <strong>Next Step: </strong>
                  {nextStep}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!canDownloadLatestVersion}
                  onClick={() => handleDownloadFile()}
                >
                  Download most recent version SOP
                </Button>
                {(sop.status === 'under-review' || sop.status === 'pending') &&
                  canSendToFinalConfirmation && (
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={SendToFinalConfirmation.isLoading}
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendToFinalConfirmation()}
                    >
                      Send to final confirmation
                    </Button>
                  )}
                {sop.status === 'pending-final-confirmation' &&
                  canApproveOrReturnToReview && (
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={ApproveSOP.isLoading}
                      variant="contained"
                      color="primary"
                      onClick={() => handleApproveSOP()}
                    >
                      Approve SOP
                    </Button>
                  )}
                {sop.status === 'pending-final-confirmation' &&
                  canApproveOrReturnToReview && (
                    <Button
                      style={{ marginLeft: 5 }}
                      disabled={SendToReview.isLoading}
                      variant="contained"
                      color="primary"
                      onClick={() => handleSendToReview()}
                    >
                      Return to review
                    </Button>
                  )}
              </Grid>
              {(sop.status === 'under-review' || sop.status === 'pending') && (
                <Grid container spacing={3} style={{ padding: 5 }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2">
                      Replace the SOP with your redlines.
                    </Typography>
                    {files && files.length > 0 && (
                      <UploadItem>
                        <Grid container>
                          <Grid item xs={12}>
                            <List
                              style={{
                                marginBottom: 2,
                                width: '100%',
                              }}
                            >
                              {files.map((file: any, idx: number) => {
                                return (
                                  <ListItem
                                    alignItems="flex-start"
                                    key={`item_${idx}`}
                                  >
                                    <div
                                      className="upload-item-info"
                                      key={`div_${idx}`}
                                    >
                                      <Description
                                        key={`DescriptionIcon_${idx}`}
                                        fontSize="small"
                                        color="primary"
                                        className="upload-item-icon"
                                      />
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="span"
                                        key={`Typography_${file.name}`}
                                      >
                                        {file.name.length > 200
                                          ? `${file.name.substring(0, 15)}...`
                                          : file.name}
                                      </Typography>
                                      <IconButton
                                        size="small"
                                        key={`Button_${idx}`}
                                        onClick={() => {
                                          handleUploadFileRemove(idx)
                                        }}
                                      >
                                        <Delete
                                          key={`DeleteButton_${idx}`}
                                          className="upload-item-icon"
                                          fontSize="small"
                                        />
                                      </IconButton>
                                    </div>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Grid>
                        </Grid>
                      </UploadItem>
                    )}
                    {(files === null || files.length === 0) && (
                      <UploadDragDrop
                        disabled={!canCreateRedline}
                        linkText="Upload File"
                        text=" Word files only (max. 200mb)"
                        size="small"
                        multiple={false}
                        onDrop={(acceptedFiles: any) => {
                          handleDroppedFiles(acceptedFiles)
                        }}
                        maxSize={MAX_SIZE_FILE}
                        onDropRejected={handleFileRejected}
                        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword"
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Button
                        disabled={
                          files === null ||
                          files.length === 0 ||
                          UploadSOPMutation.isLoading
                        }
                        data-cy="load-sop-button"
                        variant="contained"
                        color="primary"
                        title="Upload SOP"
                        type="button"
                        onClick={() => handleUpload()}
                      >
                        Upload
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  )
}

export default SOPVersioning
